<template>
    <div>
        <ul class="tips">
            <h6>温馨提示</h6>
            <li>1.关联账号共享积分即账号A与账号B积分合并，并且共用一张会员等级卡。如A关联B，B客户关联C则A/B/C共用一个会员等级卡</li>
            <li>2.备用联系方式登录金顶{{sysName}}的所有操作均属于当前会员的操作。即备用方式与会员共同拥有同一个会员信息。</li>
        </ul>

        <el-form @click.native.prevent :inline="true" class="private" style="margin-bottom: 0"
            label-suffix=":" :model="PB" :rules="rules"
            v-loading="loading" ref="form" label-width="152px">

            <el-form-item label="共享积分账号编号" prop="custCode">
                <el-input placeholder="请输入客户编号" v-model.trim="PB.custCode" maxlength="20" class="width2"></el-input>
            </el-form-item>

            <el-form-item label="备注">
                <el-input v-model.trim="PB.remark" class="width4" maxlength="50"></el-input>
            </el-form-item>

            <el-button type="primary" @click.stop="handleSaveBtn" v-if="auth.save">保存</el-button>
        </el-form>

        <template v-if="auth.log">
            <h5 class="field-title">关联账号历史记录</h5>
            <el-table :data="logList" empty-text="暂无记录">
                <el-table-column label="客户编号" prop="cust_code"></el-table-column>
                <el-table-column label="共享积分账号" prop="phone">
                    <template slot-scope="scope">
                        <div v-if="!auth.seePhone">{{scope.row.phone}}</div>
                        <el-button v-else @click="handlePhoneParam(scope.row)"
                            class="cell-list" type="text">
                            {{scope.row.phone}}
                        </el-button>
                    </template>

                </el-table-column>
                <el-table-column label="备注" prop="remake"></el-table-column>
                <el-table-column label="操作人" prop="empName"></el-table-column>
                <el-table-column label="操作时间" prop="create_time"></el-table-column>
            </el-table>            
        </template>

        <div class="field" v-if="accountList.length > 1">
            <h5 class="field-title">已关联账号</h5>
            <ul :class="'figure ' + (accountList.length === 2 ? 'double' : 'triple')">
                <li v-for="(account, index) in accountList" :key="account.id"
                    :class="('account' + index) + (account.self ? ' self' : '')">
                    <span>{{ account.name }}</span>
                    <span>{{ account.custCode }}</span>
                </li>
                <em>{{ sumInteger }}</em>
            </ul>
        </div>
    <Phones :params.sync="phoneList"></Phones>
    </div>
</template>

<script>
import Vue from "vue";
import Phones from "@/components/Phones.vue";

export default {
    name: 'RelativeAccount',

    props: {
        profile: {
            required: true,
            type: Object
        }
    },
    components: {
        Phones
    },

    data () {
        return {
            loading: false,
            logList: [],     // 关联日志
            accountList: [], // 关联账号列表
            sumInteger: 0,   // 共享积分
            auth: {
                save: false, // 添加账号的权限
                log: false,  // 查看日志的权限
            },

            PB: {
                custCode: "",
                remark: ""
            },

            rules: {
                custCode: {
                    required: true,
                    message: "请输入客户编号",
                    trigger: "blur"
                }
            },
            phoneList: [],
            sysName: '',
        }
    },

    created () {
		this.auth.save = this.$hasAuthFor("api/customer/saveShareCust");
		this.auth.log = this.$hasAuthFor("api/customer/getShareLog");
        this.auth.seePhone = this.$hasAuthFor("/api/customer/queryAllPhone");
        this.fetchData()
        this.sysIconMap = JSON.parse(localStorage.getItem("sysIconMap"))
        if (this.sysIconMap) {
            if (this.sysIconMap.name) {
                this.sysName = this.sysIconMap.name
            } else {
                this.sysName = '管家中心'
            }

        }
    },

    methods: {
        //查看电话号码
        handlePhoneParam(row) {
            this.loading = true;
            let _phoneList = []
            this.$axios({
                url: "api/customer/queryAllPhone",
                params: {
                    id: row.id
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        for (const key in res.data.data) {
                            if (res.data.data[key]) {
                                _phoneList.push(res.data.data[key])
                            }
                        }
                        this.phoneList = _phoneList
                        this.loading = false;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: "获取电话号码失败",
                        type: "error"
                    })
                    console.warn(reason)
                    this.loading = false;
                })
        },
        fetchData () {
            if (this.loading) return;
            this.loading = true;
            const params = {custId: this.profile.id}
            const fetchlog = this.auth.log ? this.$axios({ url: "api/customer/getShareLog", params}) : Promise.resolve({
                code: 2000,
                data: {
                    list: []
                }
            })
            const fetchAccount = this.$axios({ url: "api/customer/getShareTeamList", params})

            Promise.allSettled([fetchlog, fetchAccount])
            .then(([{value: logRes}, {value: accountRes}]) => {
                if (logRes?.code === 2000) {
                    this.logList.splice(0, this.logList.length, ...logRes.data.list);
                }
                if (accountRes?.code === 2000) {
                    const accountList = accountRes.data.list.sort((p, n) => n.self - p.self);
                    this.accountList.splice(0, this.accountList.length, ...accountList);
                    this.sumInteger = accountRes.data.sumInteger;

                    // 更新视图之后，调整3个关联账号的上面那个的位置
                    Vue.nextTick()
                    .then(function () {
                        if (accountList.length > 2) {
                            const els = document.querySelectorAll(".account2");
                            Array.prototype.forEach.call(els, el => {
                                // el.style.width = el.clientWidth + "px";
                                el.style.marginLeft = `calc(50% - ${el.clientWidth / 2}px)`
                            })
                        }
                    })
                }
                this.loading = false;
            })
        },

        handleSaveBtn () {
            // if (this.accountList.length >= 3) return this.$message({
            //     message: "此账号关联账号数已满，不能在与其他会员进行关联",
            //     type: "warning"
            // });
            console.log(this.profile);

            const {name, phone,cust_code} = this.profile;

            if (cust_code == this.PB.custCode) return this.$message({
                message: "不能绑定自己为共享积分账号",
                type: "warning"
            });
            let _parr = []
            let _pStr = ''
            if(phone){
                 _parr = phone.split(',')
                 console.log(_parr);
                 _pStr = _parr.join(' | ')
            }
            this.$refs.form.validate()
            .then(() => {
                return this.$confirm(`要将 ${this.PB.custCode} 和当前用户 "${name}-${cust_code}-${_pStr}" 绑定吗`)
            })
            .then(() => {
                this.loading = true;
                const data = {
                    ...this.PB,
                    custId: this.profile.id
                }
                return this.$axios({ url: "api/customer/saveShareCust", data, method: "post" })
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        // sharetype: 1备用号码 2关联账号
                        message: res.data.sharetype === 1 ? `保存成功, ${this.PB.phone}在${this.sysName}的操作均为会员${phone}的操作` : "保存成功",
                        type: "success"
                    });
                    this.loading = false;
                    this.resetForm();
                    this.fetchData();
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                if (reason === "cancel") console.log("用户取消了保存");
                else if (reason === false) console.log("填写不正确");
                else {
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "warning"
                    });
                    this.loading = false;
                    console.log("保存失败", reason);
                }
            })
        },

        resetForm () {
            this.PB.phone = 
            this.PB.remark = "";
        }
    }
}
</script>

<style lang='scss' scoped>
    .tips {
        position: relative;
        padding-left: 5em;
        font-size: 14px;
        line-height: 1.6;
        color: #FF6666;

        h6 {
            position: absolute;
            left: 0;
            font-size: 1em;
        }
    }

    .el-form {
        margin-top: 16px;
        padding: 16px 16px 2px;
        background-color: #F4F7FA;

        .el-form-item {
            margin-right: 32px;
        }
    }

    .field {
        padding: 16px 0;
        // padding-top: 16px;
        // &:before {
        //     content: "";
        //     position: absolute;
        //     left: 0; right: 0;
        //     height: 16px;
        //     background-color: #F0F2F5;
        // }
    }

    .field-title {
        margin-top: 16px;
        padding: 1em 0;
        font-size: 14px;
        line-height: 28px;
        border-bottom: 1px solid rgba($color: #000000, $alpha: .1);
    }

    .figure {
        position: relative;
        margin: 70px auto 16px;
        background-position: center;
        background-repeat: no-repeat;

        li {
            display: flex;
            position: absolute;
            height: 32px;
            line-height: 32px;
            border: 1px solid rgba($color: #CD944D, $alpha: .2);
            color: #CD9855;

            span {
                padding-left: 10px;
                padding-right: 10px;
                white-space: nowrap;

                &:first-child {
                    background-color: rgba($color: #CD944D, $alpha: .1);
                }
            }

            &.self {
                border-color: #CD944D;

                span:first-child {
                    background-color: #CD944D;
                    color: white;
                }
            }

        }

        em {
            position: absolute;
            right: 0; left: 0;
            top: 0; bottom: 0;
            height: 1em;
            margin: auto;
            text-align: center;
            color: white;
            font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            font-weight: bold;
        }

        &.double {
            width: 239px;
            height: 159px;
            background-image: url(./images/double.png);

            .account0 {
                right: 247px;
                top: 0;
                bottom: 0;
                margin: auto
            }

            .account1 {
                left: 247px;
                top: 0;
                bottom: 0;
                margin: auto
            }
        }

        &.triple {
            width: 224px;
            height: 213px;
            background-image: url(./images/triple.png);

            .account0 {
                right: 232px;
                top: 120px;
            }

            .account1 {
                left: 232px;
                top: 120px;
            }

            .account2 {
                top: -40px;
            }

            em {
                padding-top: 16px;
            }
        }
    }
</style>