import forList from "@/mixin/forList";
export default {
    name: 'Follower',
    mixins: [forList],

    data() {
        return {

            // search params 列表查询参数
            SP_: {
                fuzzyQueryType: 0,   // 模糊查询类型 0-客户编号，1-昵称 2-unionid
                fuzzyQueryValue: "", // 搜索类容
                state: "",           // 是否关注公众号，0否，1是
                startTime: "",       // 开始时间
                endTime: "",         // 结束时间
            },

            searchTypeList: [],      // 查询类型集合
            stateList: [],           // 关注状态集合

            auth: {
                refresh: false,      // 重新加载用户并推送房产的权限
                export: false,       // 导出的权限
            },
            posting: false,          // （数据库）刷新粉丝数据中

            labelWidth: 130,         // 查询栏的label宽度，纯样式控制
        };
    },

    computed: {
        cssLabelWidth: function () {
            return `width: ${this.labelWidth}px`
        }
    },

    methods: {
        // 获取列表
        fetchData() {
            if (this.loading) return;
            this.loading = true;

            this.$axios({
                url: "api/wechatFans/colList",
                params: this.extractParams()
            })
            .then(res => {
                if (res.code === 2000) {
                    this.total = res.data.total;
                    const list = res.data.list;
                    this.tableData.splice(0, this.tableData.length, ...list)
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取粉丝列表失败", reason);
                this.$message({
                    message: reason.msg || "获取粉丝列表失败",
                    type: "warning"
                })
            })
            .then(() => this.loading = false)
        },

        // 获取选项配置
        fetchConfig () {
            this.$axios({ url: "api/wechatFans/getListCond" })
            .then(res => {
                if (res.code === 2000) {
                    for (let key in res.data) {
                        this[key] = res.data[key]
                    }
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取下拉配置失败", reason);
                this.$message({
                    message: reason.msg || "获取下拉配置失败",
                    type: "warning"
                })
            })
        },

        // 点击按钮：重新加载用户并推送房产
        handleRefreshBtn () {
            this.posting = true;
            this.$axios({ url: "api/wechatFans/loadFans" })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "刷新粉丝数据成功",
                        type: "success"
                    })
                    // this.handleClearBtn();
                    this.SP.pageNum = 1;
                    this.fetchData();
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("刷新粉丝数据失败", reason);
                this.$message({
                    message: reason.msg || "刷新粉丝数据失败",
                    type: "warning"
                })
            })
            .then(() => this.posting = false)
        },

        // 点击导出
        handleExportBtn () {
            // 检查有无时间条件，没有的话不能导出
            const {startTime, endTime} = this.SP_;
            if (!(startTime || endTime)) {
                return this.$message({
                    message: "请选择导出的时间范围",
                    type: "error"
                });
            }

            this.$confirm("确认导出当前条件下的用户列表数据吗？")

            .then(() => {
                this.posting = true
                const params = {
                    ...this.extractParams(true),
                    startTime,
                    endTime
                };
                return this.$oxios({
                    url: "api/download/exportFansList",
                    method: "post",
                    data: {
                        content: JSON.stringify(params)
                    }
                })
            })
            
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                } else if (res.code !== 1003) return Promise.reject(res)
            })

            .catch(reason => {
                // this.$confirm拒绝结果是值 cancel
                if (reason === "cancel") {
                    console.log("取消了")
                } else {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                console.warn("导出失败", reason);
            })
            .then(() => this.posting = false)


        },
    },

    created () {
        this.fetchConfig();
        this.auth.refresh = this.$hasAuthFor("api/wechatFans/loadFans");
        this.auth.export = this.$hasAuthFor("api/download/exportFansList");
    }
}