import forList from "@/mixin/forList";
import Phones from "@/components/Phones.vue";


export default {
    name: 'PureList',
    mixins: [forList],
    components: { Phones },
    data () {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: { // 存储高级搜索面板的临时的查询参数
                fuzzyQueryType: 1,
                fuzzyQueryValue: "",
                startTime: "",
                endTime: "",
                status: "",
            },
            totalCountLoading: false,  // 总条数获取中
            actionList: [
                {
                    label: "详情",
                    name: "profile"
                },
                {
                    label: "系统赠送明细",
                    name: "system"
                },
                {
                    label: "积分充值",
                    name: "recharge"
                }
            ],
            customerSearchTypeList:[], // 客户列表查询下拉集合
            customerStatusList:[],     // 客户状态集合

            operating: false,   // 操作数据中：删除、导出等执行时（通信中）
            pageFlag: 0,        // 保存一个随机数字，在获取列表数量前随机生成，在请返回时验证是否是最新一次请求
            phoneList: [],
        }
    },

    computed: {
        currentCustomerSearchType () {
            return this.customerSearchTypeList.find(item => item.value === this.SP_.fuzzyQueryType)
        },

        buttonFreezing () {
            return this.loading || this.operating
        },
        
        bulkFreezing () {
            return this.buttonFreezing || !this.checkedlist.length
        }
    },

    methods: {
        //查看电话号码
        handlePhoneParam(row) {
            this.loading = true;
            let _phoneList = []
            this.$axios({
                url: "api/customer/queryAllPhone",
                params: {
                    id: row.id
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        for (const key in res.data.data) {
                            if (res.data.data[key]) {
                                _phoneList.push(res.data.data[key])
                            }
                        }
                        this.phoneList = _phoneList
                        this.loading = false;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: "获取电话号码失败",
                        type: "error"
                    })
                    console.warn(reason)
                    this.loading = false;
                })
        },
        clearSP_trim () {
            this.SP_.fuzzyQueryType = 1
        },
        // 获取列表数据
        async fetchData() {
            if (this.loading) return;
            this.loading = true;
            this.totalCountLoading = true;
            const params = this.extractParams(); // 格式化查询参数

            this.$axios({
                url: "api/customer/registerCustList",
                params
            })
            .then(res => {
                if (res.code === 2000) {
                    this.total = res.data?.count;
                    const list = res.data?.registerCustList;
                    list && this.tableData.splice(0, this.tableData.length, ...list);

                    // const couponConfAll = res.data?.couponConfAll;
                    // if (couponConfAll) this.couponOpts = couponConfAll.map(it => {
                    //     if (it.name.length > 21) it.shortName = it.name.slice(0, 19) + "...";
                    //     return it;
                    // });
                    this.loading = false;
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.$message({
                    message: "获取用户列表失败",
                    type: "error"
                })
                console.warn(reason)
                this.loading = false;
            })
        },

        // 获取配置配置参数
        async fetchConfig() {
            const res = await this.$axios({ url: "api/customer/getRegisterCondition" })

            if (res.code === 2000) {
                // 把获取的配置列表设为 data 中对应的配置列表
                for (let key in res.data) {
                    this[key] = res.data[key]
                }
            } else if (res.code !== 1003) {
                console.warn("获取参数失败")
            }
        },

        handleAction (action, scope) {
            const type = action.name;
            const data = scope.row;
            this.$router.push(`/user/user/${data.id || 5}/${type}`);
        },

        // 点击导出
        handleExportBtn () {
            // 检查有无时间条件，没有的话不能导出
            const {startTime, endTime} = this.SP_;
            if (!(startTime || endTime)) {
                this.handleAdvancedSearchBtn();
                return setTimeout(() => this.$message({
                    message: "请选择导出的时间范围",
                    type: "error"
                }), 100);
            }

            this.$confirm("确认导出当前条件下的用户列表数据吗？")

            .then(() => {
                this.operating = true
                const params = this.extractParams(true);
                return this.$oxios({
                    url: "api/customer/downRegisterCustList",
                    method: "post",
                    data: {
                        content: JSON.stringify(params)
                    }
                })
            })

            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    this.closeAdvanceSearch();
                } else if (res.code !== 1003) return Promise.reject(res)
            })

            .catch(reason => {
                if (reason === "cancel") {
                    console.log("取消了")
                } else {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                console.warn("导出失败", reason);
            })
            .then(() => this.operating = false)
        }
    },

    created() {
        this.fetchConfig();
		this.auth.export = this.$hasAuthFor("api/customer/downRegisterCustList");
		this.auth.detail = this.$hasAuthFor("api/customer/getCustInfo");
        this.auth.seePhone = this.$hasAuthFor("/api/customer/queryAllPhone");
    }
}