import forList from "@/mixin/forList";

export default {
    name: 'Irregular',
    mixins: [forList],

    data () {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {                       // 存储高级搜索面板的临时的查询参数
                fuzzyQueryType: 1,
                fuzzyQueryValue: "",
                startTime: "",
                endTime: "",
                type: "",              // 初始来源
                status: "",            // 状态
            },
            customerSearchTypeList:[], // 客户列表查询下拉集合
            customerSourceList:[],     // 客户初始来源
            customerStatusList:[],     // 客户状态集合

            operating: false,   // 操作数据中：删除、导出等执行时（通信中）
        }
    },

    computed: {
        currentCustomerSearchType () {
            return this.customerSearchTypeList.find(item => item.value === this.SP_.fuzzyQueryType)
        },

        buttonFreezing () {
            return this.loading || this.operating
        },
        
        bulkFreezing () {
            return this.buttonFreezing || !this.checkedlist.length
        },
    },

    methods: {

        // 获取列表数据
        async fetchData() {
            if (this.loading) return;
            this.loading = true;
            const params = this.extractParams(); // 格式化查询参数

            this.$axios({
                url: "api/customer/colAbnormalList",
                params
            })
            .then(res => {
                if (res.code === 2000) {
                    const list = res.data?.customerList;
                    list && this.tableData.splice(0, this.tableData.length, ...list);
                    this.total = res.data.customerCount;
                    this.loading = false;
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.$message({
                    message: "获取用户列表失败",
                    type: "error"
                })
                console.warn(reason)
                this.loading = false;
            })
        },

        // 获取配置配置参数
        async fetchConfig() {
            const res = await this.$axios({ url: "api/customer/getAbnormalCondition" })

            if (res.code === 2000) {
                for (let key in res.data) {
                    this[key] = res.data[key]
                }
            } else if (res.code !== 1003) {
                console.warn("获取参数失败")
            }
        },

        handleAction (action, scope) {
            const type = action.name;
            const data = scope.row;
            this.$router.push(`/user/user/${data.id || 5}/${type}`);
        },

        // 清空 SP_: 将高级搜索的字段恢复为默认值（见 data.SP_）
        clearSP_ () {
            for (let key in this.SP_) {
                const oVal = this.SP_[key];
                switch (key) {
                    case "pageSize":
                    case "pageNum":
                        break;

                    case "searchType":
                    case "fuzzyQueryType":
                        this.SP_[key] = 1;
                        break;

                    default: 
                        if (oVal.constructor === Array) this.SP_[key] = [];
                        else if (oVal.constructor === Object) this.SP_[key] = {};
                        else this.SP_[key] = "";
                        break;
                }
            }
        },

        // 批量删除
        async handleBulkDelete () {
            const sure = await this.$confirm("确定要删除选中的客户吗");
            if (!sure) return;

            const ids = this.checkedlist.map(item => item.id).join()

            const res = await this.$axios({
                url: "api/customer/del",
                method: "post",
                data: { ids }
            });

            const success = 2000 == res.code;
            this.$message({
                message: success ? "批量删除客户成功" : (res.msg || "批量删除客户失败"),
                type: success ? "success" : "warn"
            });
            success && this.fetchData()
        },

        // 点击导出
        handleExportBtn () {
            // 检查有无时间条件，没有的话不能导出
            const {startTime, endTime} = this.SP_;
            if (!(startTime || endTime)) {
                this.handleAdvancedSearchBtn();
                return setTimeout(() => this.$message({
                    message: "请选择导出的时间范围",
                    type: "error"
                }), 100);
            }

            this.$confirm("确认导出当前条件下的用户列表数据吗？")

            .then(() => {
                this.operating = true
                const params = this.extractParams(true);
                return this.$oxios({
                    url: "api/download/exportCsrList",
                    method: "post",
                    data: {
                        content: JSON.stringify(params)
                    }
                })
            })
            
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "导出成功",
                        type: "success"
                    })
                    this.closeAdvanceSearch();
                } else if (res.code !== 1003) return Promise.reject(res)
            })

            .catch(reason => {
                // this.$confirm拒绝结果是值 cancel
                if (reason === "cancel") {
                    console.log("取消了")
                } else {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                console.warn("导出失败", reason);
            })
            .then(() => this.operating = false)


        },
    },

    created() {
        this.fetchConfig();
		this.auth.export = this.$hasAuthFor("api/download/exportCsrList");
		this.auth.detail = this.$hasAuthFor("api/customer/getCustInfo");
		this.auth.delete = this.$hasAuthFor("api/customer/del");
    },
}