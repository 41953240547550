<!-- 列表表格展示的可以编辑状态 -->
<template>
    <el-popover class="editable-state" popper-class="operate-box" ref="pop" :disabled="!editable">
        <ul>
            <template v-for="item in options">
                <li type="text" :key="item.name || item.label || item"
                    :class="{active: item == current}"
                    @click.stop="handleItemClick(item)">
                    {{ item.name || item.label || item }}</li>
            </template>
        </ul>

        <span slot="reference">{{ current.name || current.label || current }} <i v-if="editable" class="el-icon-edit"></i></span>
    </el-popover>
</template>

<script>
export default {
    name: 'EditableState',
    props: {
        options: {
            type: Array,
            default: () => [
                {
                    name: "是",
                    value: 1
                },
                {
                    name: "否",
                    value: 2
                }
            ]
        },
        value: {
            type: Number,
            default: 1
        },
        editable: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            coolDownCount: 0, // 防止重复点击的冷却计数（秒）
        }
    },

    computed: {
        current: function () {
            return this.options.find(item => item.value === this.value)
        }
    },

    methods: {

        handleItemClick (item) {
            if (!(this.coolDownCount || item === this.current)) {
                this.coolDownCount = 3;
                setTimeout(() => {
                    this.coolDownCount = 0
                }, this.coolDownCount*1000)

                this.$emit("change", item.value || item.id || item)
                this.$refs.pop.doClose()
            }
        },

    },

    // watch: {
    //     value () {
    //     }
    // }
}
</script>

<style lang='scss' scoped>

    
    span {
        cursor: pointer;
    }

</style>