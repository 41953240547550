import forList from "@/mixin/forList";
// 生成一个随机数字 默认6位数
function createNumber (count = 6) {
    return Math.floor(Math.random()*(10**count))
}

export default {
    name: "voucherList",
    mixins: [forList],
    noSaveState: 1,

    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                searchType:  1,    // 搜索类型
                searchValue: "",   // 搜索类容
                areaId: '',
                searchState: "",   // 状态
                searchDraw: "",    // 是否已领取
                // startTime: "",  // 开始时间
                // endTime: "",    // 结束时间
            },
            totalCountLoading: false,  // 总条数获取中
            exporting: false,      // 导出中
            // pageFlag: 0,           // 保存一个随机数字，在获取列表数量前随机生成，在请返回时验证是否是最新一次请求

            //#region 选项配置
            searchTypeList1: [      // 查询条件
                { value: 1, name: "客户编号"},
                { value: 2, name: "券名称"},
                { value: 3, name: "券编号" },
                { value: 4, name: "申请员工code" }
            ],
            drawTypeList: [
                { value: 0, name: "待领取"},
                { value: 1, name: "已领取"}
            ],
            stateList: [           // 状态
                { value: 0, name: "失效"},
                { value: 1, name: "可使用"},
                { value: 2, name: "已使用"},
                { value: 3, name: "已作废" },
                { value: 5, name: "待解锁" }
            ],
            //#endregion
            tabPosition: 'first',
            areaList: [],          // 区域
            tableData2: [],
            pageSize2: 10,
            pageNum2: 1,
            total2: 0,

        };
    },

    computed: {
        buttonFreezing () {
            return this.loading || this.operating
        },

        bulkFreezing () {
            return this.buttonFreezing || !this.checkedlist.length
        }
    },

    methods: {
        handleClearBtn2() {
            if (this.tabPosition == 'first') {
                this.handleClearBtn()
            } else {
                this.clearSP_();
                this.handleSearch2()
            }
        },
        handleSearch2() {
            if (this.tabPosition == 'first') {
                this.handleSearch()
            } else {
                this.updateSP_toSP();
                this.pageNum2 = 1;
                this.closeAdvanceSearch(); // 关闭高级搜索面板
                this.savePageState(); // 保存列表状态
                this.fetchData2()
            }
        },

        goOrderDetail(data) {
            this.$router.push(`/order/list/${data.orderId}/${null}`);
        },

        handlePageSizeChange2(e) {
            this.pageSize2 = e;
            this.pageNum2 = 1;
            this.fetchData2();
        },
        // 分页组件发生 当前页 改变事件
        handlePageNoChange2(e) {
            this.pageNum = e;
            this.fetchData2();
        },

        getConfig() {
            this.$get("api/loginLog/getListCond")
                .then(res => {
                    if (res.code === 2000) {
                        for (let key in res.data) {
                            this[key] = res.data[key]
                        }
                    }
                })
        },
        handleClick() {
            console.log('tabPosition', this.tabPosition);
            if (this.tabPosition == 'first') {
                this.fetchData()
            } else {
                this.fetchData2()
            }
        },
        fetchData() {
            if (this.loading) return;
            this.loading = true;
            this.totalCountLoading = true;
            const params = this.extractParams(); // 格式化查询参数
            params.type = 1
            this.$axios({
                url: "api/custCoupon/queryCustCouponAll",
                params
            })
            .then(res => {
                if (res.code === 2000 && res.data.list) {
    
                    // this.total = res.data.count;
                    const list = res.data.list.map(item => {
                        if (item.custCode == 1)
                            item.custCode = ""
                        
                        if (item.status === 2) item.unSelectable=true

                        return item;
                    });
                    this.tableData.splice(0, this.tableData.length, ...list)
                    this.loading = false;

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
                this.loading = false;
            })

            // #region 请求分页数据 ==========
            delete params.pageSize; delete params.pageNum;
            // const pageFlag = createNumber();
            // this.pageFlag = pageFlag;
            // this.total = 0;
            // params.pageFlag = pageFlag;
            this.$axios({
                url: "api/custCoupon/queryCustCouponAllCount",
                params
            })
            .then(res => {
                if (res.code === 2000) {
                    // if (res.data.pageFlag === this.pageFlag)
                        this.total = res.data?.count;
                    // else console.log("获取到了数量, 但已经不是最新的请求了, 所以舍弃");
                } else if (res.code !== 1003) {
                    console.warn("获取总数失败")
                }
                this.totalCountLoading = false;
            })
            .catch(reason => {
                console.warn(reason)
                this.totalCountLoading = false;
            })

            // #endregion ==========
        },
        fetchData2() {
            if (this.loading) return;
            this.loading = true;
            this.totalCountLoading = true;
            const params = this.extractParams(); // 格式化查询参数

            if (this.tabPosition == 'first') {
                params.type = 1
            } else {
                params.type = 2
            }
            params.pageNum = this.pageNum2
            params.pageSize = this.pageSize2
            console.log(params);

            this.$axios({
                url: "api/custCoupon/queryCustCouponAll",
                params
            })
                .then(res => {
                    if (res.code === 2000 && res.data.list) {

                        // this.total = res.data.count;
                        const list = res.data.list.map(item => {
                            if (item.custCode == 1)
                                item.custCode = ""

                            if (item.status === 2) item.unSelectable = true

                            return item;
                        });
                        this.tableData2.splice(0, this.tableData2.length, ...list)
                        this.loading = false;

                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    console.warn("获取数据失败", reason);
                    this.$message({
                        message: reason.msg || "获取数据失败",
                        type: "warning"
                    });
                    this.loading = false;
                })

            // #region 请求分页数据 ==========
            delete params.pageSize; delete params.pageNum;
            // const pageFlag = createNumber();
            // this.pageFlag = pageFlag;
            // this.total = 0;
            // params.pageFlag = pageFlag;
            this.$axios({
                url: "api/custCoupon/queryCustCouponAllCount",
                params
            })
                .then(res => {
                    if (res.code === 2000) {
                        // if (res.data.pageFlag === this.pageFlag)
                        this.total2 = res.data?.count;
                        // else console.log("获取到了数量, 但已经不是最新的请求了, 所以舍弃");
                    } else if (res.code !== 1003) {
                        console.warn("获取总数失败")
                    }
                    this.totalCountLoading = false;
                })
                .catch(reason => {
                    console.warn(reason)
                    this.totalCountLoading = false;
                })

            // #endregion ==========
        },

        clearSP_trim () {
            this.SP_.searchType = 1
        },

        // 批量操作：删除
        handleBulkDelete () {
            const {checkedlist} = this;
            this.$confirm(`您确定要删除${checkedlist.length}个优惠么?`, "温馨提示")
            .then(() => {
                this.loading = true;
                return this.$axios({
                    url: "api/custCoupon/deleteCustCoupon",
                    method: "post",
                    data: { custCouponIds: checkedlist.map(it => it.id).join() }
                })
            })
            .then(res => {
                this.loading = false
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    })
                    this.checkedlist.splice(0, this.checkedlist.length)
                    if (this.tabPosition == 'first') {
                        this.fetchData();
                    }
                    if (this.tabPosition == 'second') {
                        this.fetchData2();
                    }

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.loading = false
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "error"
                    })
                }
            })
        },

        // 点击一键作废
        handleTotalDelete() {
            const data = this.extractParams(true);
            this.$confirm(
                "请精准的筛选条件进行查询劵进行作废",
                "温馨提示",
                {
                    confirmButtonText: '已选择',
                    cancelButtonText: '取消',
                }
            )
            .then(() => {
                this.loading = true;
                return this.fetchCount(data)
            })
            .then(count => {
                this.loading = false;
                return this.$confirm(
                    `满足条件的优惠共有${count}张, 确定都需要作废么?`,
                    "温馨提示",
                    {
                        confirmButtonText: '确定作废',
                        cancelButtonText: '取消',
                    }
                )
            })
            .then(() => {
                this.loading = true;
                return this.totalDelete(data)
            })
            .then(() => {
                this.loading = false;
                this.$message({
                    message: "作废成功",
                    type: "success"
                })
                this.fetchData();
            })
            .catch(reason => {
                if (reason === "cancel") {
                    console.log("用户取消")
                } else {
                    this.$message({
                        message: reason.msg || "作废失败",
                        type: "error"
                    })
                }
                this.loading = false
            })
        },
        // 点击同步员工code
        handleCusCode() {
            // const data = this.extractParams(true);
            this.$confirm(
                "确定同步员工code？",
                "温馨提示",
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }
            )

                .then(() => {
                    this.loading = true;
                    return this.updateCustCouponSaleCode()
                })
                .then(() => {
                    this.loading = false;
                    this.$message({
                        message: "同步成功",
                        type: "success"
                    })
                    this.fetchData();
                })
                .catch(reason => {
                    if (reason === "cancel") {
                        console.log("用户取消")
                    } else {
                        this.$message({
                            message: reason.msg || "同步失败",
                            type: "error"
                        })
                    }
                    this.loading = false
                })
        },


        fetchCount (data) {
            return this.$axios({
                url: "api/custCoupon/queryBatchDeleteCount", method: "post",
                data
            })
            .then(res => {
                if (res.code === 2000) {
                    return res.data.count;
                } else if (res.code !== 1003) return Promise.reject(res)
            })
        },

        updateCustCouponSaleCode() {
            return this.$axios({
                url: "api/custCoupon/updateCustCouponSaleCode", method: "post",

            })
                .then(res => {
                    if (res.code === 2000) {
                        return res
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
        },

        totalDelete (data) {
            return this.$axios({
                url: "api/custCoupon/batchDeleteCustCoupon", method: "post",
                data
            })
            .then(res => {
                if (res.code === 2000) {
                    return res
                } else if (res.code !== 1003) return Promise.reject(res)
            })
        },

        isRowSelectable (row) {
            return !row.unSelectable;
        },

        // 点击导出
        handleExportBtn () {
            // 检查有无时间条件，没有的话不能导出
            // const {startTime, endTime} = this.SP_;
            // if (!(startTime || endTime)) {
            //     return this.$message({
            //         message: "请选择导出的时间范围",
            //         type: "error"
            //     });
            // }

            let _type = 1
            if (this.tabPosition == 'first') {
                _type = 1
            } else {
                _type = 2
            }
            this.$confirm("确认导出当前条件下的优惠券数据吗？")

            .then(() => {
                this.posting = true
                // const params = {
                //     ...this.extractParams(true),
                //     // startTime,
                //     // endTime
                // };
                return this.$axios({
                    url: "api/custCoupon/downCustCoupon",
                    method: "post",
                    data: {
                        // content: JSON.stringify(params)
                        ...this.extractParams(true),
                        type: _type
                    }
                })
            })
            
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                } else if (res.code !== 1003) return Promise.reject(res)
            })

            .catch(reason => {
                // this.$confirm拒绝结果是值 cancel
                if (reason === "cancel") {
                    console.log("取消了")
                } else {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                    console.warn("导出失败", reason);
                }
            })
            .then(() => this.posting = false)
        }
    },

    created () {
        this.getConfig();
		this.auth.export = this.$hasAuthFor("api/custCoupon/downCustCoupon");
		this.auth.batchDelete = this.$hasAuthFor("api/custCoupon/deleteCustCoupon");
        this.auth.updateCustCouponSaleCode = this.$hasAuthFor("api/custCoupon/updateCustCouponSaleCode");
		this.auth.totalDelete = this.$hasAuthFor("api/custCoupon/batchDeleteCustCoupon") &&
            this.$hasAuthFor("api/custCoupon/queryBatchDeleteCount");
    }
};