export function createIssueData () {
    return {
        cusfile     : [],
        couponConfId: "",
        remark: "",
        type: '', //选择发劵客户
        actId: '',//赠礼活动名称
        actName: '',//赠礼活动名称


    }
}
export const rules = {
    couponConfId: { required: true, message: "请选择券类型", trigger: "blur" },
    cusfile: { required: true, message: "请上传客户表格", trigger: "blur" },
    type: { required: true, message: "请选择发劵客户", trigger: "blur" },
    // actId: { required: true, message: "请查询选择活动名称", trigger: "blur" },
    actId: { required: true, message: "请查询选择活动名称", trigger: "change" },
    none: {
        validator: function (r, v, cb) {
            cb();
        },
    },
}
export default { createIssueData, rules };