<!-- 变更区域 -->
<template>
    <el-dialog class="update-region"
        :visible="viewIsReady"
        @close="handleClose"
        title="变更区域"
        width="560px">

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-tree
                :data="tree_"
                node-key="areaId"
                ref="tree"
                show-checkbox
            ></el-tree>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'UpdateRegion',
    props: [ "item", "tree" ],

    data () {
        return {
            viewIsReady: false,
            tree_: [],
            PB: {},
            rules: {}
        }
    },

    computed: {
        replaceItem () {
            return this.item || {}
        },
        // replaceTree () {
        //     return this.tree || []
        // }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.viewIsReady = true;
                this.$nextTick(() => {
                    // console.log("init...");
                    // var nodes = this.$refs.tree.store.nodesMap;
                    // // for (var i in nodes) {
                    // //     nodes[i].expanded = false;
                    // // }
                    // this.tree.forEach(system => {
                    //     // node[]
                    // });
                    this.tree_ = [...this.tree];
                })
            }
            else this.tree_ = []
        }
    },

    methods: {
        async handleSave () {
            const checked = this.$refs.tree.getCheckedNodes();
            if (!checked.length) return this.$message({
                message: "必须选择一个区域进行保存",
                type: "error"
            });

            const systypeList = checked.map(item => item.sysType);
            const _systypeList = Array.from(new Set(systypeList));
            if (systypeList.length != _systypeList.length) return this.$message({
                message: "同一个系统只能勾选一个区域",
                type: "error"
            });

            const loadingRef = this.$loading({
                target: ".update-region .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "api/customer/updateCustArea",
                    method: "post",
                    data: {
                        custIds:  this.item.map(({id}) => id).join(),
                        areaInfo: checked.map(({sysType, areaId}) => (sysType + "-" + areaId)).join()
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.$nextTick(() => {
                this.$refs.tree.setCheckedKeys([]);
                this.$refs.form.clearValidate();
            })
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>