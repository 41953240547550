<!-- 变更券的状态 -->
<template>
    <el-dialog class="verify-voucher"
        :visible="viewIsReady"
        @close="handleClose"
        title="变更券的状态"
        width="560px">
        <!-- {{replaceItem}} -->

        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="卡劵名称">
                {{ replaceItem.coupon_name }}
            </el-form-item>
             <el-form-item label="卡劵状态">
                {{ replaceItem.statusName }}
            </el-form-item>

            <el-form-item label="变更状态" prop="status">
                <el-select v-model="PB.status" clearable class="width3">
                    <el-option
                        v-for="o in bannerPositionEnum"
                        :key="o.value"
                        :label="o.name"
                        :value="o.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea"
                    maxlength="200"
                    class="width3"></el-input>
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">确认变更</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'VerifyVoucher',
    props: [ "item" ],

    data () {
        return {
            viewIsReady: false,
            PB: {
                remark: "",
                status:''
            },
            rules: {
                status: {
                    required: true,
                    message: "请选择变更状态",
                    trigger: "blur"
                },
            },
            bannerPositionEnum:[
                {name:'请选择',value:''},
                {name:'可使用',value:'1'},
                {name:'已使用',value:'2'},
            ]
        }
    },

    computed: {
        replaceItem () {
            return this.item || {}
        },
        
    },

    watch: {
        item (val, old) {
            if (val && val !== old) {
                this.PB.remark = val.remark;
                this.viewIsReady = true;
                if(val.status==1){
                    this.bannerPositionEnum = [
                        {name:'请选择',value:''},
                        {name:'已使用',value:'2'},
                    ]
                }else if(val.status==5){
                    this.bannerPositionEnum = [
                        {name:'请选择',value:''},
                            {name:'可使用',value:'1'},
                            {name:'已使用',value:'2'},
                    ]
                }
            }
        }
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".verify-voucher .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "api/custCoupon/updateCustCoupon",
                    method: "post",
                    data: {
                        custCouponId: this.item.id,
                        remark: this.PB.remark,
                        status: this.PB.status,
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        resetData () {
            this.PB.remark = "";
            this.PB.status = "";
            this.$refs.form.clearValidate();
        },

        handleClose () {
            this.resetData();
            this.viewIsReady = false;
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .state-radio-group {
        flex-wrap: wrap;
        height: auto;

        .el-radio {
            margin-bottom: 10px;
        }
    }
</style>