<template>
    <div>
        <div class="set--bottom-foot-padding">
            <el-table :data="billlist" empty-text="暂无记录">
                <el-table-column label="类型" prop="type"></el-table-column>
                <el-table-column label="关联单号" prop="order_sn"></el-table-column>
                <el-table-column label="积分" prop="money"></el-table-column>
                <el-table-column label="时间" prop="create_time">
                    <template slot-scope="scope">
                        <span v-html="scope.row.create_time"></span>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="change_desc"></el-table-column>
            </el-table>
        </div>
            
        <!-- list-foot-bar 默认插入元素（分页组件）会被插入右侧，
        slot="left" 会插入左侧（预想为列表选中状态） -->
        <list-foot-bar>
            <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                :current-page.sync="SP.pageNum"
                :page-size="SP.pageSize"
                :total="total"
                @size-change="handlePageSizeChange"
                @current-change="handlePageNoChange"
            ></el-pagination>
        </list-foot-bar>
    </div>
</template>

<script>
import forList from "@/mixin/forList";
export default {
    name: 'PointsChanges',
    mixins: [ forList],
    noSaveState: true,

    props: {
        id: {
            required: true,
            type: Number
        }
    },

    data () {
        return {
            billlist: []
        }
    },

    methods: {
        // 获取客户积分明细
        fetchData () {
            if (this.loading) return;
            this.loading = true;
            
            this.$axios("api/billDetail/getListByCusId", {
                params: { ...this.extractParams(), custId: this.id, type: 1 }
            })
            
            .then(res => {
                if (res.code === 2000) {
                    this.total = res.data.total;
                    this.billlist = res.data.list.map(item => {
                        // 根据 up_down 将积分转换为正负数
                        item.money = item.up_down == 2 ? -item.money : "+" + item.money;
                        return item;
                    })
                    this.loading = false;

                } else if (res.code !== 1003) {
                    this.$message({
                        message: res.msg || "获取客户积分明细失败，请稍后重试",
                        type: "warning"
                    })
                }
            })
        },

        // 分页组件发生 每页数据条数 改变事件
        handlePageSizeChange(e) {
            this.SP.pageSize = e;
            this.SP.pageNum = 1;
            this.fetchData();
        },

        // 分页组件发生 当前页 改变事件
        handlePageNoChange(e) {
            this.SP.pageNum = e;
            this.fetchData();
        },
    }
}
</script>

<style lang='scss' scoped>
    
</style>