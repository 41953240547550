<template>
    <el-dialog
        :visible="viewIsReady"
        @close="handleClose"
        title="劵转赠记录"
        width="800px"
        class="shareing-logs"
    >

        <el-table :data="tableData">
            <el-table-column label="客户编号" prop="custcode"></el-table-column>
            <el-table-column label="客户姓名" prop="name"></el-table-column>
            <el-table-column label="接收人编号" prop="givecustcode"></el-table-column>
            <el-table-column label="接收人姓名" prop="givename"></el-table-column>
            <el-table-column label="接收时间" prop="give_timeName"></el-table-column>
        </el-table>

        <div slot="footer">
            <el-button @click="handleClose" type="primary">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'VoucherShareLogs',
    props:  ["item"],

    data () {
        return {
            viewIsReady: false,
            tableData: []
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.viewIsReady = true;
                this.fetchData()
            }
        }
    },

    methods: {
        async fetchData () {
            let loadingInstance = this.$loading(({
                target: ".shareing-logs .el-dialog__body"
            }));
            
            try {
                const res = await this.$axios({
                    url: "api/custCoupon/queryConversionCouponLog",
                    method: "post",
                    data: {
                        sourceCouponId: this.item.source_coupon_id
                    }
                })

                if (res.code !== 2000) throw res;
                this.tableData = res.data.conversionCouponLogList;
                
            } catch (reason) {
                console.log("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败"
                })
                this.handleClose()
            }

            loadingInstance.close();
        },

        handleClose () {
            this.viewIsReady = false;
            this.tableData = [];
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped></style>