import forList from "@/mixin/forList";
import EditableState from "@/components/EditableState.vue"

export default {
    name: 'CouponList',
    mixins: [forList],
    components: {
        "editable-state": EditableState,
    },

    data () {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                fuzzyQueryType: 0,
                fuzzyQueryValue: "",
                type: "",            
                status: "",                    
                ustatus: "",              
                startTime: "",              
                endTime: "",             
                payType: "",
            },
            // 表格项（行）的操作菜单
            actionList: [],

            category1: [],       // 一级分类
            category2: [],       // 二级分类
            useTypeList:[
                {value:1,name:"兑积分"},
                {value:2,name:"兑现"}
            ],      // 支付类型集合
            searchTypeList: [],  // 搜索条件下拉集合
            typeList:[],       // 业务方向
            statusList:[],         // 区域集合
            ustatusList:[],         // 区域集合
            operating: false,   // 操作数据中：删除、导出等执行时（通信中）
            detailInfo: {
                void_time:'',
                voidEmpPhone:'',
                voidEmpName:'',
                void_txt:'',
                void_src:'',
            },
            dialogVisible:false,
            void_form: {
                ids: [],
                remark: '',
                // fileUrl: ''
                fileList:[]
            },
            fileList:[],
            rules:{
                remark: [
                    { required: true, message: '请填写理由', trigger: 'blur' }
                  ]
            },
            dialogFormVisible: false,
            // 图片上传的请求头
            uploadHeaders: {
                token: this.$store.state.token
            },
            uploading: false,// 状态：正在上传图片
        }
    },

    computed: {
        currentCustomerSearchType () {
            return this.searchTypeList.find(item => item.value === this.SP_.fuzzyQueryType)
        },

        buttonFreezing () {
            return this.loading || this.operating
        },
        
        bulkFreezing () {
            return this.buttonFreezing || !this.checkedlist.length
        }
    },

    methods: {

        // 获取列表数据
        async fetchData() {
            if (this.loading) return;
            this.loading = true;
            const params = this.extractParams(); // 格式化查询参数
            try {
                const res = await this.$axios({
                    url: "api/coupon/colList",
                    params
                });
    
                if (res.code === 2000) {
                    this.tableData = res.data?.list;
                    this.total = res.data.total;

                } else if (res.code !== 1003) {
                    return this.$message({
                        message: res.msg || "获取列表失败",
                        type: "warning"
                    })
                }

                this.loading = false;
            } catch (reason) {
                console.warn(reason);
                this.loading = false;
            }

        },

        // 获取配置配置参数
        async fetchConfig() {
            try {
                const res = await this.$axios({ url: "api/coupon/getListCond" })
                if (res.code === 2000) {
                    // 把获取的配置列表设为 data 中对应的配置列表
                    for (let key in res.data) {
                        this[key] = res.data[key]
                    }
                } else if (res.code !== 1003) {
                    console.warn("获取参数失败")
                }
            } catch (reason) {
                console.warn(reason)
            }
        },

		// 获取商品分类配置
		fetchCategories (pid = 0) {
			this.$axios({
				url: "api/category/queryCategoryById",
				method: "post",
				data: {pid}
			})

			.then(res => {
				if (res.data?.list) {
					const cate = pid ? this.category2 : this.category1;
					cate.splice(0, cate.length, ...res.data.list)
				} else {
					return Promise.reject(res);
				}
			})

			.catch(reason => console.log(reason))
			
		},

        handleAction (action, scope) {
            const type = action.name;
            const data = scope.row;
            switch (type) {
                case "detail":
                    this.$router.push(`/order/list/${data.id}/${null}`);
                    break;

                case "checked":
                    break;

                case "LogisticsFollowUp":
                    
                    break;

            }
        },

        handleExceed() {
            // 上传超过五张图
            this.$message({
              message: "只能上传五张图片",
              type: "error",
            });
        },

        beforeFileUpload(file) {
            // 上传图标之前，判断图标格式
            const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 5;
    
            if (!isImage) {
            this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 5MB!');
            }
            if (isImage && isLt2M) {
            this.void_form.fileList.push(file)
            return true;
            }
            return false;
        },

        handleProgress(e, file) {
            this.uploading = true;
            const index = this.void_form.fileList.indexOf(file);
            this.$set(this.void_form.fileList, index, file)
        },

        // 删除文件
        handleIconDelete (index) {
            this.void_form.fileList.splice(index, 1);
        },

        // 上传文件成功
        handleUploadSuccess (res, file) {
            this.uploading = false;
            const index = this.void_form.fileList.indexOf(file);
            if (res.code === 2000) {
                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.void_form.fileList, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
            } else {
                this.void_form.fileList.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },
        
        // 点击导出数据
        handleExportBtn () {
            // 检查有无时间条件，没有的话不能导出
            const {startTime, endTime} = this.SP_;
            if (!(startTime || endTime)) {
                this.handleAdvancedSearchBtn();
                return setTimeout(() => this.$message({
                    message: "请选择导出的时间范围",
                    type: "error"
                }), 100);
            }

            this.$confirm("确认导出当前条件下的数据吗？")
            .then(() => {
                this.operating = true
                const params = this.extractParams(true);
                return this.$oxios({
                    url: "api/download/exportCoupon",
                    method: "post",
                    data: {
                        content: JSON.stringify(params)
                    }
                })
            })
            
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    this.closeAdvanceSearch();
                } else if (res.code !== 1003) return Promise.reject(res)
            })

            .catch(reason => {
                // this.$confirm拒绝结果是值 cancel
                if (reason === "cancel") {
                    console.log("取消了")
                } else {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                console.warn("导出失败", reason);
            })
            .then(() => this.operating = false)
        },

        //点击作废
        handleDetail(row){
            this.detailInfo = {...row};
            this.dialogVisible = true
        },
        //作废优惠券
        handleBulkButton(){
            const items = this.checkedlist;
            const res = items.some(item => {
                return item.ustatus == 3
            })
            if(res){
                this.$message({
                    message: '选中的包含已作废优惠券！',
                    type: 'warning'
                  });
            }else{
                this.dialogFormVisible = true
            }
        },

        //清空作废优惠券表单
        resetForm() {
            this.void_form = {
                ids: [],
                remark: '',
                fileList:[]
            }
        },

        //作废优惠券保存
        handleSaveButton(){
            const items = this.checkedlist
            const data = {
                ids: items instanceof Array ? 
                    items.map(item => item.id).join() :
                    items.id,
                remark: this.void_form.remark,
                fileUrl: this.void_form.fileList.map(item => item.path).join()
            }
            this.$axios({ url: "api/coupon/delete", method: "post", data })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    this.resetForm();
                    this.fetchData();
                } else if (res.code !== 1003) {
                    return Promise.reject(res)
                }
            })
            .catch(reason => {
                this.$message({
                    message: reason.msg || "失败",
                    type: "warn"
                });
            })
            .then(() => this.dialogFormVisible = false)
        }

    },

    created() {
        this.fetchConfig();
        this.fetchCategories();
		this.auth.export = this.$hasAuthFor("api/download/exportCoupon");
		this.auth.delete = this.$hasAuthFor("api/coupon/delete");
    }
}