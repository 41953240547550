import forList from "@/mixin/forList";
import Phones from "@/components/Phones.vue";

export default {
    name: "ScoreLogs",
    mixins: [forList],
    components: { Phones },
    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {                    // 存储高级搜索面板的临时的查询参数
                fuzzyQueryType: 0,
                fuzzyQueryValue: "",
                areaId: "",            // 区域id
                type: "",              // 账单类型
                payWay: "",            // 支付方式
                sys: "",               // 操作端
                remark: "",            // 备注
                startTime: "",         // 开始时间
                endTime: "",           // 结束时间
            },

            // #region 配置参数 ----------
            paywayList: [],     // 支付方式
            sysList: [],        // 操作端
            searchTypeList: [], // 查询条件
            areaList: [],       // 区域
            billTypeList: [],   // 操作方式
            // #endregion

            operating: false,   // 操作数据中：删除、导出等执行时（通信中）
            expandRowKeys: [],  // 展开所有
            expandAll: false,
            phoneList: [],
        };
    },

    computed: {
        currentCustomerSearchType () {
            return this.searchTypeList.find(item => item.value === this.SP_.fuzzyQueryType)
        },

        buttonFreezing () {
            return this.loading || this.operating
        },
    },

    methods: {
        //查看电话号码
        handlePhoneParam(row) {
            this.loading = true;
            let _phoneList = []
            this.$axios({
                url: "api/customer/queryAllPhone",
                params: {
                    id: row.custId
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        for (const key in res.data.data) {
                            if (res.data.data[key]) {
                                _phoneList.push(res.data.data[key])
                            }
                        }
                        this.phoneList = _phoneList
                        this.loading = false;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: "获取电话号码失败",
                        type: "error"
                    })
                    console.warn(reason)
                    this.loading = false;
                })
        },
        createRowKey(e) {
            return e.id;
        },
        handleExpandChange(row, expandList) {
            if (expandList.length === this.tableData.length) this.expandAll = true;
            else if (expandList.length === 0) this.expandAll = false;
        },
        handleExpandAll() {
            this.expandAll = !this.expandAll;
            if (this.expandAll) {
                this.expandRowKeys = this.tableData.map(it => it.id)
            } else {
                this.expandRowKeys = []
            }
        },

        fetchData() {
            if (this.loading) return;
            this.loading = true;

            this.$axios({
                url: "api/billDetail/colList",
                params: this.extractParams()
            })
            .then(res => {
                if (res.code === 2000 && res.data.list) {
                    this.total = res.data.total;
                    const list = res.data.list.map((item, index) => {
                        const data = {
                            ...item,
                            id: index + 1,
                            money: item.money ? item.up_down == 1 ? "+" + item.money : -item.money : item.money,
                            // vip_money: item.vip_money ? item.up_down == 1 ? "+" + item.vip_money : -item.vip_money : item.vip_money,
                            // sn: item.order_sn || item.cust_code,
                            dateAndTime: item.createTime.split(" ")
                        };
    
                        return data;
                    });
                    this.tableData.splice(0, this.tableData.length, ...list)

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        getConfig () {
            this.$get("api/billDetail/getListCond")
            .then(res => {
                if (res.code === 2000) {
                    for (let key in res.data) {
                        this[key] = res.data[key]
                    }
                }
            })
        },

        // 点击导出
        handleExportBtn () {
            // 检查有无时间条件，没有的话不能导出
            const {startTime, endTime} = this.SP_;
            if (!(startTime || endTime)) {
                this.handleAdvancedSearchBtn();
                return setTimeout(() => this.$message({
                    message: "请选择导出的时间范围",
                    type: "error"
                }), 100);
            }

            this.$confirm("确认导出当前条件下的明细列表数据吗？")

            .then(() => {
                this.operating = true;
                const params = this.extractParams(true);
                return this.$oxios({
                    url: "api/download/exportBillDetail",
                    method: "post",
                    data: {
                        content: JSON.stringify(params)
                    }
                })
            })
            
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
                        message: "已加入导出任务，去消息管理的下载通知中查看吧",
                        type: "success"
                    })
                    this.closeAdvanceSearch();
                } else if (res.code !== 1003) return Promise.reject(res)
            })

            .catch(reason => {
                // this.$confirm拒绝结果是值 cancel
                if (reason === "cancel") {
                    console.log("取消了")
                } else {
                    this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
                }
                console.warn("导出失败", reason);
            })
            .then(() => this.operating = false)

        },

    },

    created () {
        this.getConfig();
		this.auth.export = this.$hasAuthFor("api/download/exportBillDetail");
        this.auth.seePhone = this.$hasAuthFor("/api/customer/queryAllPhone");
    },
};