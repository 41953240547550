<!-- 商品规格属性选择器 -->
<template>
    <div>
        <el-checkbox-group :value="checkedAll">
            <el-checkbox label="all"
                @change.native="handleCheckAll">全选</el-checkbox>
        </el-checkbox-group>

        <el-checkbox-group :value="checked">
            <el-checkbox v-for="item in specs" :key="item.detailid"
                @change.native="handleChange(item.detailid)"
                :label="item.detailid">{{ item.skuname }}</el-checkbox>
        </el-checkbox-group>
    </div>
</template>

<script>
export default {
    name: 'SpecsCheckor',
    props: {
        specs: {
            type: Array,
            required: true
        },
        checked: {
            type: Array,
            required: true
        }
    },

    data () {
        return {}
    },

    computed: {
        checkedAll () {
            return this.checked.length >= this.specs.length;
        }
    },

    methods: {
        handleCheckAll (e) {
            if (!this.checkedAll)
                this.$emit("update:checked", this.specs.map(it => it.detailid))
            else this.$emit("update:checked", []);
        },
        handleChange (id) {
            const copy = [...this.checked];
            if (copy.includes(id)) copy.splice(copy.indexOf(id), 1);
            else copy.push(id)
            this.$emit("update:checked", copy)
        },
    }
}
</script>

<style lang='scss' scoped></style>