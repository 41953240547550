import forList from "@/mixin/forList";

export default {
    name: "RedemptionList",
    mixins: [forList],

    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                fuzzyQueryType: 0,
                fuzzyQueryValue: "",
                type: "",
                status: "",
                startTime: "",         // 开始时间
                endTime: "",           // 结束时间
            },
            exporting: false, // 导出中

            //#region 选项配置
            searchTypeList: [],    // 查询条件
            typeList: [],          // 类型
            statusList: [],  // 审核状态
            dialogDetailVisible: false,
            profile: {},
            activities: [],
            detailLoading: false,
            imgList: []
        };
    },

    computed: {
        buttonFreezing() {
            return this.loading || this.exporting
        },
    },

    methods: {

        //详情
        handleClick(row) {
            this.detailLoading = true
            this.dialogDetailVisible = true
            this.$axios({
                url: "api/couponAudit/getInfo",
                params: { auditId: row.id }
            }).then((res) => {
                if (res.code === 2000) {
                    this.detailLoading = false
                    this.profile = res.data?.info
                    this.imgList = res.data?.info.Files.split(',').map(item => {
                        return this.$baseURL + item
                    })
                    this.activities = res.data?.detailList.map(item => ({
                        ...item,
                        color: this.getColor(item.status),
                    }))
                }
            })
        },

        //审核状态
        getColor(status) {
            switch (status) {
                case 1:
                    return '#909398';
                case 2:
                    return '#EF8030';
                case 3:
                    return '#D40000';
                case 4:
                    return '#909398';
                case 5:
                    return '#2DA641';
                case 6:
                    return '#D40000';
            }

        },

        async fetchData() {

            if (this.loading) return;
            this.loading = true;
            const res = await this.$axios({
                url: "api/couponAudit/colList",
                params: { ...this.extractParams() }
            })

            if (res.code === 2000 && res.data.list) {
                this.total = res.data.total;
                this.tableData = res.data.list;
                this.loading = false;
            }
        },

        // 获取配置配置参数
        async fetchConfig() {
            try {
                const res = await this.$axios({ url: "api/couponAudit/getListCond" })
                if (res.code === 2000) {
                    // 把获取的配置列表设为 data 中对应的配置列表
                    for (let key in res.data) {
                        this[key] = res.data[key]
                    }
                } else if (res.code !== 1003) {
                    console.warn("获取参数失败")
                }
            } catch (reason) {
                console.warn(reason)
            }
        }
    },

    created() {
        this.fetchConfig();
        this.auth.detail = this.$hasAuthFor("api/couponAudit/getInfo");
    }
};