<!-- 添加、编辑字典项 -->
<template>
    <el-dialog
        :visible="viewIsReady"
        @close="handleClose"
        title="查看完整电话"
        width="300px"
    >
        <ul  v-if="info.length>0" class="cell-list">
            <li v-for="item in info"
                :key="item"
            >
            <copy-text :text="item"></copy-text>
            <span @click.stop="copyCode(item)" style="color:#cd944d;cursor: pointer;margin-left:12px;"> 复制</span>
            <!-- <el-button @click.stop="copyCode(item)" class="copy-btn" v-dbclick>复制</el-button> -->
        </li>
        </ul>
        <span v-else style="color:#aaa;">暂无数据</span>

        <div slot="footer">
            <el-button @click="handleClose" type="primary">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'ViewParams',
    props: ["params"],

    data () {
        return {
            viewIsReady: false,
            info: []
        }
    },

    watch: {
        params (params, old) {
            if (params && params !== old) {
                this.viewIsReady = true;
                this.info = params;
            }
        }
    },

    methods: {
        // 点击复制
        copyCode(code) {
            var input = document.createElement("input"); // 创建input对象
            input.value = code; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message.closeAll();
            this.$message.success('复制成功！');
        },
       
        handleClose () {
            this.viewIsReady = false;
            this.info = [];
            this.$emit("update:params", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .cell-list {
        line-height: 1.5;

        // li {
        //     white-space: nowrap;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        // }

        li:nth-child(n+2) {
            margin-top: 10px;
        }
    }
</style>