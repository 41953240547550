import forList from "@/mixin/forList";

export default {
    name: "getTicketLogs",
    mixins: [forList],
    noSaveState: 1,

    data() {
        return {
            SP_: {
                searchType: 0,       // 搜索类型
                searchValue: "",     // 搜索类容
                searchStartTime: "", // 开始时间
                searchEndTime: "",   // 结束时间
            },
            exporting: false,      // 导出中
            searchTypeList: [
                {
                    name: "手机号",
                    value: 0
                }
            ]
        };
    },

    computed: {
        buttonFreezing () {
            return this.loading || this.exporting
        }
    },

    methods: {
        fetchData() {
            if (this.loading) return;
            this.loading = true;

            this.$axios({
                url: "api/custCoupon/queryCustCouponLog",
                params: this.extractParams()
            })
            .then(res => {
                if (res.code === 2000 && res.data.custCouponLogList) {
    
                    this.total = res.data.count;
                    this.tableData = res.data.custCouponLogList;

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // 点击导出
        handleExportBtn () {
            const {searchStartTime, searchEndTime} = this.SP_;
            if (!searchStartTime && !searchEndTime) {
                return this.$message({
                    message: "请选择查询的时间段",
                    type: "warning"
                })
            }

            this.$confirm("确认导出当前条件下的日志列表数据吗？")
            .then(() => {
                this.exporting = true;

                return this.$axios({
                    url: "api/custCoupon/downCustCouponLog",
                    method: "post",
                    data: this.extractParams(true)
                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "已加入导出任务，去消息管理的下载通知中查看吧" : res.msg || "导出失败",
                    type: success ? "success" : "warn"
                });
            })

            .catch(reason => {
                (reason == "cancel") || this.$message({
                    message: reason || "导出失败",
                    type: "error"
                });
                console.warn(reason);
            })

            .then(() => {
                this.exporting = false;
            })
        }

    },

    created () {
		this.auth.export = this.$hasAuthFor("api/custCoupon/downCustCouponLog");
    }
};