import SystemGiftList  from "./SystemGiftList.vue";
import PointsChanges   from "./PointsChanges.vue";
import RechargeForm    from "./RechargeForm.vue";
import RelativeAccount from "./RelativeAccount.vue";
import LevelLogs       from "./LevelLogs.vue";
import Voucher         from "./Voucher.vue";

export default {
    name: 'UserDetail',
    components: {
        "system-gift-list": SystemGiftList,
        "points-changes":   PointsChanges,
        "recharge-form":    RechargeForm,
        "relative-account": RelativeAccount,
        "level-logs":       LevelLogs,
        "voucher-list":     Voucher,
    },

    data () {
        return {

            activeName: "profile", // 当前激活的table
            profile: {             // 客户基本信息
                id: 0,
                name: "",
                phone: "",
                phoneOne: '',
                phoneTwo: '',
            },
            auth: {
                system:   false,  // 查看系统赠送明细权限
                recharge: false,  // 充值权限
                relative: false,  // 关联账号
                level:    false,  // 查看等级变更的权限
                voucher:  false,  // 管理优惠券的权限
            },
            loading: false,
            billSysEnum: [],
            billTypeEnum: [],
        }
    },

    methods: {

        getListParam() {
            // 获取操作端
            this.billSysEnum = []
            this.billTypeEnum = []

            this.$axios("api/billDetail/getListParam", {
                params: {}
            })

                .then(res => {
                    if (res.code === 2000) {
                        this.billSysEnum = res.data.billSysEnum || []
                        this.billSysEnum.unshift({ name: '请选择', value: -1 })
                        this.billTypeEnum = res.data.billTypeEnum || []
                        // this.billTypeEnum.unshift({ name: '请选择', value: -1, disabled: true })


                    } else if (res.code !== 1003) return Promise.reject(res)
                    // this.profile.unionid = this.profile.unionid || "fdd"; // 调试 的
                })

                .catch(reason => {
                    this.$message({
                        message: reason.msg || "获取操作端失败，请稍后重试",
                        type: "warning"
                    })
                    console.warn("获取操作端失败，请稍后重试", reason);
                });
        },

        fetchData() {
            if (this.loading) return;
            this.loading = true;

            // 获取用户详情
            this.$axios("api/customer/getCustInfo", {
                params: { id: this.profile.id }
            })
            
            .then(res => {
                if (res.code === 2000) {
                    for (let key in res.data) {
                        if (key === "supercustcode") this.profile[key] = res.data[key] == 1 ? "" : res.data[key] // 1是员工的标识
                        else if (key === "interestName") this.profile.interests = res.data[key]?.join()
                        else if (key !== "id") this.profile[key] = res.data[key]
                    }
                    this.loading = false;

                } else if (res.code !== 1003) return Promise.reject(res)
                // this.profile.unionid = this.profile.unionid || "fdd"; // 调试 的
            })
            
            .catch(reason => {
                this.$message({
                    message: reason.msg || "获取客户信息失败，请稍后重试",
                    type: "warning"
                })
                console.warn("获取客户信息失败，请稍后重试", reason);
                this.loading = false;
            });
        },

        // 复制unionid
        handleUnionIdCopy () {
            const fine = this.profile.unionid.pushToClipBoard();
            if (fine) this.$message({
                message: "复制成功",
                type: "success"
            });
            else this.$message({
                message: "复制失败, 请手动复制",
                type: "warning"
            })
        },

        // 充值成功后，刷新数据
        handleRecharged () {
            this.fetchData();
            const toWindowName = this.auth.system ? "system" : "profile";
            this.activeName = toWindowName; // 切换到充值记录tab
            if (toWindowName === "system") this.$refs[toWindowName].fetchData();
        },

        // 点击返回按钮
        handleNavigateBackBtn () {
            this.$router.back();
        }
    },

    created () {
        const id = Number(this.$route.params.id);
        if (id) {
            this.profile.id = id;
            this.fetchData();
        }

        const tab = this.$route.params.tab;
        if (tab && ["profile", "changes", "system", "recharge", "relative", "voucher", "level"].includes(tab)) {
            this.activeName = tab
        }
        this.getListParam()

		this.auth.system   = this.$hasAuthFor("api/billDetail/getListByCusId");
		this.auth.recharge = this.$hasAuthFor("api/billDetail/backRecharge");
		this.auth.relative = this.$hasAuthFor("api/customer/getShareTeamList");
		this.auth.level    = this.$hasAuthFor("api/customer/getHistoryByCusId");
		this.auth.voucher  = this.$hasAuthFor("api/customer/getHistoryByCusId") || true;
    },
}