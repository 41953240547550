import forList from "@/mixin/forList";
import Phones from "@/components/Phones.vue";
export default {
    name: "SignInLogs",
    mixins: [forList],
    noSaveState: 1,
    components: { Phones },
    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                listType: this.$route.name == "SignInLogs" ? 1 : 0,  // !! 列表类型：0 注册 1 登陆
                fuzzyQueryType: 0,     // 搜索类型
                fuzzyQueryValue: "",   // 搜索类容
                areaId: "",            // 区域id
                type: "",              // 操作类型/注册方式
                firstLogin: 0,         // 只看首次登陆：0否 1 是
                startTime: "",         // 开始时间
                endTime: "",           // 结束时间
            },
            // dateRange: "",    // 选中的时间范围（两个 Date对象 元素的数组）
            exporting: false, // 导出中

            //#region 选项配置
            searchTypeList: [],    // 查询条件
            areaList: [],          // 区域
            registerTypeList: [],  // 注册方式
            loginTypeList: [],     // 登录方式
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            phoneList: [],
            //#endregion

        };
    },

    computed: {

        buttonFreezing () {
            return this.loading || this.exporting
        },

        typeList () {
            return this.SP_.listType ? this.loginTypeList : this.registerTypeList
        }
    },

    methods: {
        //查看电话号码
        handlePhoneParam(row) {
            this.loading = true;
            let _phoneList = []
            this.$axios({
                url: "api/customer/queryAllPhoneByCode",
                params: {
                    code: row.custCode
                }
            })
                .then(res => {
                    if (res.code === 2000) {
                        for (const key in res.data.data) {
                            if (res.data.data[key]) {
                                _phoneList.push(res.data.data[key])
                            }
                        }
                        this.phoneList = _phoneList
                        this.loading = false;
                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: "获取电话号码失败",
                        type: "error"
                    })
                    console.warn(reason)
                    this.loading = false;
                })
        },

        // 提取查询参数：去掉空值的查询参数，并且:
        // - 在注册列表页面去掉 firstLogin 参数
        // - 在导出状态（传入真值forExport）下，去掉分页参数
        extractParams (forExport) {
            const stateData = forExport || this.showAdvanceSearch ? this.SP_ : this.SP;
            const params = {};
            for (let key in stateData) {
                let _val = stateData[key];
                let valFine = true;
                switch (key) {
                    case "searchType":
                    case "fuzzyQueryType":
                        // 如果没有输入关键字，搜索类型页不传
                        if (!(stateData.searchValue || stateData.fuzzyQueryValue))
                            valFine = false;
                        break;

                    default:
                        // 列表转换为字符串
                        if (_val instanceof Array) _val = _val.join();
                        // 无效的字段不传
                        if (_val == null || _val === "") valFine = false;
                        break;
                }
                valFine && (params[key] = _val)
            }

            if (forExport) {
                delete params.pageNum;
                delete params.pageSize;
            }

            // 登录列表，加上是否首次登录参数
            const {listType, firstLogin} = this.SP_;
            params.listType = listType;
            // console.log("检查listType", listType, !!listType)
            if (listType) {
                params.firstLogin = firstLogin
            } else {
                params.firstLogin = undefined;
            }
            
            return params;
            
            // const {
            //     listType,
            //     fuzzyQueryType,
            //     fuzzyQueryValue,
            //     areaId,
            //     type,
            //     firstLogin,
            //     startTime,
            //     endTime,
            //     pageNum,
            //     pageSize
            // } = this.SP_;

            // const params = { listType };

            // if (!forExport) { // 非导出，加上分页参数
            //     params.pageNum = pageNum;
            //     params.pageSize = pageSize
            // }

            // if (fuzzyQueryValue) { // 传入了模糊查询字符, 加上类型
            //     params.fuzzyQueryType = fuzzyQueryType;
            //     params.fuzzyQueryValue = fuzzyQueryValue
            // }

            // // 登录列表，加上是否首次登录参数
            // listType && (params.firstLogin = firstLogin);
            
            // // 非空参数带上
            // areaId &&  (params.areaId = areaId);
            // type &&  (params.type = type);
            // startTime &&  (params.startTime = this.productDateStr(startTime));
            // endTime &&  (params.endTime = this.productDateStr(endTime));

            // return params;
        },

        fetchData() {
            if (this.loading) return;
            this.loading = true;

            this.$axios({
                url: "api/loginLog/colList",
                params: this.extractParams()
            })
            .then(res => {
                if (res.code === 2000 && res.data.list) {
    
                    this.total = res.data.total;
                    const list = res.data.list.map(item => {
                        if (item.custCode == 1)
                            item.custCode = ""
    
                        return item;
                    });
                    this.tableData.splice(0, this.tableData.length, ...list)

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        getConfig () {
            this.$get("api/loginLog/getListCond")
            .then(res => {
                if (res.code === 2000) {
                    for (let key in res.data) {
                        this[key] = res.data[key]
                    }
                }
            })
        },

        // 点击导出
        handleExportBtn () {
            const {startTime, endTime} = this.SP_;
            if (!startTime && !endTime) {
                return this.$message({
                    message: "请选择查询的时间段",
                    type: "warning"
                })
            }
            
            this.$confirm("确认导出当前条件下的日志列表数据吗？")
            .then(() => {
                this.exporting = true;

                return this.$oxios({
                    url: "api/download/exportLoginLog",
                    method: "post",
                    data: {
                        content: JSON.stringify(this.extractParams(true))
                    }
                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "已加入导出任务，去消息管理的下载通知中查看吧" : res.msg || "导出失败",
                    type: success ? "success" : "warn"
                });
            })

            .catch(reason => {
                // this.$confirm拒绝结果是值 false，对于拒绝的情况
                // ，不用 message 提示，因为表单控件上已有提示
                (reason == "cancel") || this.$message({
                    message: reason || "导出失败",
                    type: "error"
                });
                console.warn(reason);
            })

            .then(() => {
                this.exporting = false;
            })

        },

        // 清空 SP_: 将高级搜索的字段恢复为默认值（见 data.SP_）
        clearSP_ () {
            for (let key in this.SP_) {
                const oVal = this.SP_[key];
                switch (key) {
                    case "pageSize":
                    case "pageNum":
                        break;

                    case "searchType":
                    case "fuzzyQueryType":
                        this.SP_[key] = 0;
                        break;

                    case "firstLogin": // 此处和@mixins/forList 不同
                        this.SP_[key] = 1;
                        break;

                    case "listType": // 此处和@mixins/forList 不同
                        this.SP_[key] = this.$route.name == "SignInLogs" ? 1 : 0;
                        break;

                    default: 
                        if (oVal.constructor === Array) this.SP_[key] = [];
                        else if (oVal.constructor === Object) this.SP_[key] = {};
                        else this.SP_[key] = "";
                        break;
                }
            }
        },

    },

    created () {
        this.getConfig();
		this.auth.export = this.$hasAuthFor("api/download/exportLoginLog");
        this.auth.seePhone = this.$hasAuthFor("/api/customer/queryAllPhone");
    },

    watch: {
        "SP_.firstLogin": function() {
            this.handleSearch()
        }
    }
};