<template>
    <div class="goods-picker" v-loading="loading">
        <el-form class="goods-picker--search private" :inline="true"
            @submit.native.prevent label-suffix=":">

            <el-form-item label="商品名称">
                <el-input v-model.trim="SP_.goodsName"
                    @keyup.native.enter.stop="handleInputEnter" class="width4">
                </el-input>
            </el-form-item>
            <el-button type="primary" @click="handleSearch">搜索</el-button>
        </el-form>
        <el-table :data="tableData" :height="260"
                @selection-change="handleSelectionChange">

            <el-table-column type="selection"></el-table-column>
            <el-table-column label="商品编号" prop="good_sn"></el-table-column>
            <el-table-column label="商品名称" prop="name" min-width="100"></el-table-column>
            <!-- <el-table-column label="销售价格">
                <template slot-scope="scope">
                    <span v-if="scope.row.pay_type == '元'">{{ scope.row.minpay }} - {{ scope.row.maxpay }}</span>
                </template>
            </el-table-column>
            <el-table-column label="兑换积分">
                <template slot-scope="scope">
                    <span v-if="scope.row.pay_type == '积分'">{{ scope.row.minpay }} - {{ scope.row.maxpay }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="规格属性" min-width="260">
                <template slot-scope="scope">
                    <specs-checkor v-if="!scope.row.isSingle" :specs.sync="scope.row.specs" :checked.sync="scope.row.checked"></specs-checkor>
                    <span class="disable" v-else>单规格商品</span>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination class="goods-picker--pagination"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="SP.pageNum"
            :page-size="SP.pageSize"
            :total="total"
            @size-change="handlePageSizeChange"
            @current-change="handlePageNoChange"
        ></el-pagination>
    </div>
</template>

<script>
import forList from "@/mixin/forList";
import SpecsCheckor from "./SpecsCheckor.vue";
export default {
    name: "GoodsPickList",
    mixins: [forList],
    noSaveState: true,
    components: {
        "specs-checkor": SpecsCheckor
    },

    props: {
        shield: { // shield 列表用于禁用选项
            type: Array,
            default () {
                return []
            }
        },
        refName: { // 标识
            type: String,
            default: "test"
        }
    },

    data () {
        return {
            SP_: {
                goodsName: ""
            }
        }
    },

    methods: {
        // 获取列表数据
        fetchData() {
            if(this.refName=='addvoucher'){
                if(this.SP_.goodsName ==''){
                    this.tableData = []
                    this.total = 0;
                    return
                }
            }

            if (this.loading) return;
            this.loading = true;
            // 清空勾选
            this.checkedlist.splice(0, this.checkedlist.length);
            const params = this.extractParams();   // 格式化查询参数
            // if (!params.isSale) params.isSale = 1; // 修复bug: 第一次自动请求时，SP里面没有isSale

            return this.$axios({ url: "api/goods/colSkuList", params })
            .then(res => {
                if (res.code === 2000) {
                    const list = res.data?.goodsList.map(it => {
                        const {goodsSkuList} = it;
                        it.isSingle = goodsSkuList.length < 2 && (goodsSkuList[0] == undefined || !goodsSkuList[0]?.sku_val);
                        it.specs = it.goodsSkuList;
                        it.checked = it.goodsSkuList.map(it => it.detailid);

                        return it;
                    });
                    list && this.tableData.splice(0, this.tableData.length,
                        ...list);
                    this.total = res.data.count;

                } else if (res.code !== 1003) {
                    return Promise.reject(res)
                }
            })
            .catch(reason => {
                this.$message({
                    message: reason.msg || "获取列表失败",
                    type: "warning"
                });
                console.log("获取列表失败", reason)
            })
            .then(() => this.loading = false)
        },
    },

    watch: {
        checkedlist: {
            handler (arr) {
                console.log("goodsPicker 中的选中项改变了，向父组件发送改变后的数据", arr);
                this.$emit("change", arr);
            }
        }
    }
}
</script>

<style lang='scss' scoped>
    .goods-picker--pagination {
        margin-top: 1.5em;
    }
    .disable {
        color: darkgray;
    }
</style>