<template>
    <div>
        <div class="set--bottom-foot-padding">
            <!-- 搜索 -->
            <div >
                <el-row :gutter="16" class="flex a-center">
                    <el-col :span="6">
                        <div class="flex a-center">
                            <div class="" style="flex-shrink: 0;">卡劵名称：</div>
                            <el-input maxlength="20" style="width: 218px;" placeholder="请输入" v-model.trim="searchValue"></el-input>
                        </div>
                        
                    </el-col>
                    <el-col :span="6">
                        <div class="flex a-center">
                            <div class="">状态：</div>
                            <el-select v-model="searchState" placeholder="请选择">
                                <el-option
                                v-for="item in searchStateList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="9">
                        <div class="flex a-center">
                            <div class="" style="flex-shrink: 0;">领取状态：</div>
                            <el-select v-model="searchDrawState" placeholder="请选择">
                                <el-option
                                v-for="item in searchDrawStateList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                                </el-option>
                            </el-select>
                            <div class="flex a-center" style="margin-left:20px;">
                                <el-button  type="primary" size="mini" @click="onSearch">搜索</el-button>
                                <el-button  size="mini" @click="clearSearch">清空</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <el-table :data="billlist" empty-text="暂无记录"
                @selection-change="handleSelectionChange" v-loading="loading">
                <el-table-column type="selection" v-if="!noAnyAuth"></el-table-column>
                <el-table-column label="编号"    prop="id"></el-table-column>
                <el-table-column label="卡劵名称" prop="coupon_name">
                    <template slot-scope="scope">
                       <div style="cursor: pointer; color:blue; " @click='seeQuanDetail(scope.row)'>{{scope.row.coupon_name}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="折扣" prop="coupon_discount_text"></el-table-column>
                <el-table-column label="抵扣价值" prop="coupon_amount"></el-table-column>
                <el-table-column label="状态"    prop="statusName">
                    <template slot-scope="scope">
                        <!-- v-if="scope.row.status == 4" -->
                        <el-button
                            type="text"
                            @click="handleStatusClick(scope.row)"
                        >{{ scope.row.statusName }}</el-button>
                        <!-- <span v-else>{{ scope.row.statusName }}</span> -->
                    </template>
                </el-table-column>
                <el-table-column label="领取时间">
                    <template slot-scope="scope" v-if="scope.row.longdrawTime">
                        <div v-for="time in scope.row.longdrawTime.split(' ')" :key="scope.$index+'c'+time">
                            {{time}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="到期时间">
                    <template slot-scope="scope" v-if="scope.row.validtime">
                        <div v-for="time in scope.row.validtime.split(' ')" :key="scope.$index+'v'+time">
                            {{time}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="使用时间/失效时间">
                    <template slot-scope="scope" v-if="scope.row.longmaketime">
                        <div v-for="time in scope.row.longmaketime.split(' ')" :key="scope.$index+'m'+time">
                            {{time}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="用户是否领取" prop="drawStateName"></el-table-column>
                <el-table-column label="备注"    prop="remark"></el-table-column>
            </el-table>
        </div>
            
        <!-- list-foot-bar 默认插入元素（分页组件）会被插入右侧，
        slot="left" 会插入左侧（预想为列表选中状态） -->
        <list-foot-bar :checked-num="checkedlist.length" v-if="!noAnyAuth">
            <template slot="left">
                <el-button v-if="auth.verify" @click="handleVerifyBtn"
                    :disabled="!checkedlist.length">变更劵状态</el-button>
                <el-button v-if="auth.delete" @click="handleBulkDelete"
                    :disabled="!checkedlist.length">删除券</el-button>
                <span class="batch-split"></span>
                <el-button v-if="auth.issue" @click="handleIssueBtn"
                    type="primary">发放券</el-button>
                <el-button :disabled="checkedlist.length!==1" v-if="auth.saveCouponGoods" @click="handleExchange"
                type="primary">设置兑换商品</el-button>

                    
                <!-- <el-button v-if="auth.issue" @click="handleIssueBtn"
                    :disabled="!profile.vipNo"
                    type="primary">发放券</el-button>
                <span v-if="!profile.vipNo"
                    style="margin-left: 6px; color: darkgray; font-size: 12px;">
                    无法给没有vip卡号的用户发放优惠券
                </span> -->
            </template>
            <!-- <el-pagination
                layout="total, sizes, prev, pager, next, jumper"
                :current-page.sync="SP.pageNum"
                :page-size="SP.pageSize"
                :total="total"
                @size-change="handlePageSizeChange"
                @current-change="handlePageNoChange"
            ></el-pagination> -->
        </list-foot-bar>

        <VoucherShareLogsVue :item.sync="logingItem"></VoucherShareLogsVue>
        <VerifyVoucher :item.sync="verifingItem"
            @change="fetchData"></VerifyVoucher>

        <el-dialog :visible.sync="showIssueUi" title="发放劵" width="600px">
            <el-form label-suffix=": " label-width="120px" class="private" ref="issue"
                :model="issueData" :rules="issueRules" @submit.prevent="handSubmitButton">

                <el-form-item label="券类型" prop="id">
                    <el-select v-model="issueData.id" filterable placeholder="请选择" class="width3">
                        <el-option v-for="item in couponOpts" :key="item.id" class="fff"
                            :label="item.shortName || item.name" :value="item.id"
                            :title="item.name"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否会员权益劵" class="isviprow">
                    <el-radio v-model="isVip" label="0">否</el-radio>
                    <el-radio v-model="isVip" label="1">是</el-radio>
                </el-form-item>

                <el-form-item label="附件" prop="files" class="wrap">
                    <el-upload
                        class="upload-row"
                        :action="$baseURL + 'api/common/fileSingleUpLoad'"
                        :headers="uploadHeaders"
                        :limit="3"
                        accept="image/*"
                        :show-file-list="false"
                        :file-list="issueData.files"
                        :on-exceed="handleExceed"
                        :before-upload="beforeFileUpload"
                        :on-progress="handleProgress"
                        :on-success="handleUploadSuccess"
                        :on-error="handleUploadError"
                    >
                        <el-button size="small">点击上传</el-button>
                        <div slot="tip" class="upload-tip">最多上传3张图</div>
                    </el-upload>

                    <thumbnail-list :images="issueData.files" :show-delete="true"
                        @delete="handleIconDelete"></thumbnail-list>
                </el-form-item>

                <el-form-item label="备注">
                    <el-input v-model="issueData.remark" type="textarea"
                        rows="4" class="width4" maxlength="200"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" v-loading="uploading">
                <el-button @click="handleDialogClose">取消</el-button>
                <el-button @click="handSubmitButton" type="primary" :disabled="uploading" v-dbclick>保存</el-button>
            </div>
        </el-dialog>
        <!-- 设置劵兑换商品 -->
        <el-dialog
            :visible="showAddPupop"
            @close="handleDialogClose2"
            title="设置劵兑换商品"
            class="myDialog"
            width="1080px">

            <el-radio-group @change='changetabPosition' v-model="tabPosition" style="margin-bottom: 30px;">
                <el-radio-button label="1">查看可兑换商品</el-radio-button>
                <el-radio-button label="2">添加可兑换商品</el-radio-button>
            </el-radio-group>
             <el-table v-if="tabPosition=='1'" :data="couponConfGoodsList" empty-text="暂无记录"
                @selection-change="handleSelectionChange" v-loading="loading" max-height="450">
                <el-table-column label="商品编号"    prop="good_sn"></el-table-column>
                <el-table-column label="商品名称" prop="goodsname"></el-table-column>
                <el-table-column label="规格属性" prop="skuvalNameList">
                    <template slot-scope="scope">
                        <div v-for="(item, index) in scope.row.skuvalNameList" :key="index" style="margin-bottom:5px;">{{item}}</div>
                    </template>

                </el-table-column>
                <el-table-column label="操作">
                     <template slot-scope="scope">
                        <div v-show="scope.row.type==2" style="cursor: pointer;" @click="deldata(scope.row)">删除</div>
                    </template>
                </el-table-column>
            </el-table>
            <div v-if='showAddPupopTab2' v-show="tabPosition=='2'">
                <goods-picker v-show="tabPosition=='2'" :refName='addvoucher' :shield="mygoods" @change="handleGoodsPicker"></goods-picker>
            </div>
            <div slot="footer" v-if="tabPosition=='2'">
                <el-button @click="handleDialogClose2">取消</el-button>
                <el-button @click="handConfirmButton2" type="primary" >确定选择</el-button>
            </div>
        </el-dialog>
        <!-- 查看劵兑换商品 -->
        <el-dialog
            :visible="showPupopCl"
            @close="showPupopCl=false"
            title="劵允许兑换商品"
            width="800px">

          
             <el-table  :data="couponConfGoodsList" empty-text="暂无记录"
                @selection-change="handleSelectionChange" v-loading="loadingshowPupopCl_loading">
                <el-table-column label="商品编号"    prop="good_sn"></el-table-column>
                <el-table-column label="商品名称" prop="goodsname"></el-table-column>
                <el-table-column label="规格属性" prop="skuvalNameList">
                    <template slot-scope="scope">
                        <div v-for="(item, index) in scope.row.skuvalNameList" :key="index" style="margin-bottom:5px;">{{item}}</div>
                    </template>

                </el-table-column>
            </el-table>
            <div slot="footer">
                <el-button @click="showPupopCl = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Loading } from "element-ui";
import forList from "@/mixin/forList";
import VoucherShareLogsVue from "./VoucherShareLogs.vue";
import VerifyVoucher  from "./VerifyVoucher.vue";
import GoodsPickList from "../../basic/voucherEdit/GoodsPickList.vue";



const createNewIssueData = function () {
    return {
        id: "",
        files: [],
        remark: ""
    }
}
export default {
    name: 'UserVoucher',
    mixins: [forList],
    components: {VoucherShareLogsVue, VerifyVoucher, "goods-picker": GoodsPickList},
    noSaveState: true,

    props: {
        profile: {
            required: true,
            type: Object
        }
    },

    data () {
        return {
            logingItem: null,
            billlist: [],
            auth: {            // 权限
                verify: false, // 核销（标记为已使用）
                delete: false, // 删除
                issue:  false, // 发放
                saveCouponGoods:false,//设置兑换商品
            },
            showIssueUi: false,
            issueData: createNewIssueData(), // 发券数据
            issueRules: {
                id: { required: true, message: "请选择券类型", trigger: "blur" },
                // files: { 
                //     validator: function (rule, value, cb) {
                //         if (!value.length) cb("请上传附件");
                //         else cb()
                //     },
                //     trigger: "blur"
                // }
            },
            uploadHeaders: { token: this.$store.state.token },
            couponOpts: [
                {
                    name: "券1",
                    id: 1
                },
                {
                    name: "券2",
                    id: 2
                },
                {
                    name: "券3",
                    id: 3
                }
            ],
            uploading: false,   // 正在上传
            verifingItem: null, // 正在核销的券
            searchValue:'',
            searchState:1,
            //  0失效 1待使用 2已使用 3已作废 4已转增
            searchStateList:[
                {name:'全部',value:-1},
                // {name:'失效',value:0},
                {name:'可使用',value:1},
                {name:'已使用',value:2},
                // {name:'已作废',value:3},
                {name:'已转赠',value:4},
                {name:'待解锁',value:5},
            ],
            searchDrawState:-1,
             searchDrawStateList:[
                {name:'请选择',value:-1},
                {name:'待领取',value:0},
                {name:'已领取',value:1},
            ],
            isVip:'0',//0否1是
            showAddPupop:false,
            showAddPupopTab2:false,
            tabPosition:'1',
            mygoods:[],
            addvoucher:'addvoucher',
            couponConfGoodsList:[],
            checkedGoods:[],
            showPupopCl:false,
            isRequest:false,
            loadingshowPupopCl_loading:false,
        }
    },

    computed: {
        noBatchAuth () { // 没有批量操作的权限
            return !(this.auth.verify || this.auth.delete)
        },
        noAnyAuth () {  // 没有任何操作的权限
            return this.noBatchAuth && !(this.auth.issue)
        }
    },

    methods: {
        changetabPosition(e){
            if(e==2){
                this.showAddPupopTab2 = true
            }
        },
        seeQuanDetail(row){
            console.log(row);
            this.loadingshowPupopCl_loading =true
            
            this.couponConfGoodsList = []
            this.showPupopCl=true
            return this.$axios("api/couponConf/queryCouponConfGoodsList", {
                params: { 
                    "couponConfId":row.conpon_conf_id,
                    couponId:row.id,
                }
            })
            .then(res => {
                if (res.code === 2000) {
                    this.couponConfGoodsList = res.data.couponConfGoodsList ||[]
                    this.loadingshowPupopCl_loading = false;
                } else if (res.code !== 1003) {
                    this.$message({
                        message: res.msg || "获取可兑换商品失败，请稍后重试",
                        type: "warning"
                    })
                }
            })
        },
         // 勾选、取消勾选时
        handleGoodsPicker(goods) {
            this.checkedGoods.splice(0, this.checkedGoods.length, ...goods);
        },
        // 删除
        deldata(row){
            this.$confirm('确定要删除吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
                this.loading =true
                return this.$axios({
                     url: "api/couponConf/deleteCouponConfGoods",
                    method: "post",
                    params: { 
                        "couponConfGoodsId":row.id,
                    }
                })
                .then(res => {
                    if (res.code === 2000) {
                        this.$message({
                            message: "删除成功",
                            type: "success"
                        })
                        this.queryCouponConfGoodsList()
                        this.loading = false;
                    } else if (res.code !== 1003) {
                        this.$message({
                            message: res.msg || "删除失败，请稍后重试",
                            type: "warning"
                        })
                    }
                })
			});
        },
        handleDialogClose2(){
            this.tabPosition = '1'
            this.showAddPupop = false
            this.showAddPupopTab2 = false
        },
        // 点击确定选择
        handConfirmButton2 () {
            if (!this.checkedGoods.length) return this.$message({
                message: "请选择商品",
                type: "error"
            });
            if (this.checkedGoods.some(g => !g.isSingle && !g.checked.length))
            return this.$message({
                message: "请选择商品规格",
                type: "error"
            });
            if(this.isRequest)return
            this.isRequest = true
            this.loading = true;
            return this.$oxios({
                // /api/custCoupon/saveCouponGoods
                url: "api/custCoupon/saveCouponGoods",
                method: "post",
                params: {
                    couponGoodsStr: JSON.stringify(this.checkedGoods.map(item => {
                        const checkedList = item.checked.map(id => item.specs.find(s =>
                            s.detailid == id)).filter(Boolean)
                        return {
                            couponId: this.checkedlist[0].id,
                            goodsid: item.id,
                            skuVal : item.isSingle ? "" : checkedList.map(it => it.sku_val).join()
                        }
                    }))
                }
            })
            .then(res => {
                if (res.code === 2000) {
                    this.$message({
						message: "保存成功",
						type: "success"
					})
                    this.checkedGoods = []
                    this.showAddPupopTab2 = false

                    this.isRequest = false;
                    this.loading = false;
                    this.tabPosition = '1'
                    this.queryCouponConfGoodsList()
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.log("保存失败", reason)
                this.loading = false
            })
        },
        handleExchange(){
            this.tabPosition = '1'
            this.checkedGoods = []
            this.showAddPupop = true;
            this.showAddPupopTab2 = true
            this.queryCouponConfGoodsList()
        },
        queryCouponConfGoodsList(){
            this.loading =true
            // this.couponConfGoodsList = []
            return this.$axios("api/couponConf/queryCouponConfGoodsList", {
                params: { 
                    "couponConfId":this.checkedlist[0].conpon_conf_id,
                    couponId:this.checkedlist[0].id,
                }
            })
            .then(res => {
                if (res.code === 2000) {
                    this.couponConfGoodsList = res.data.couponConfGoodsList ||[]
                    this.loading = false;
                } else if (res.code !== 1003) {
                    this.$message({
                        message: res.msg || "获取可兑换商品失败，请稍后重试",
                        type: "warning"
                    })
                }
            })
        },
        clearSearch(){
            this.searchValue = ''
            this.searchState = 1
            this.searchDrawState = -1
            this.fetchData(0,true)
        },
        onSearch(){
            this.fetchData(0,true)
        },

        fetchData (tryCount = 0,flag) {
            console.log(this.profile.vipNo);
            if(!flag){
                if (!this.profile.vipNo && tryCount < 10) {
                    setTimeout(()=>this.fetchData(++tryCount), 500); // 等待vipNo
                    return Promise.resolve();
                }
    
                if (this.loading) return Promise.resolve();
            }
            this.loading = true;
            
            return this.$axios("api/custCoupon/queryCustCouponList", {
                params: { vipNo: this.profile.vipNo ,
                    searchValue:this.searchValue,
                    searchState:this.searchState,
                    searchDrawState:this.searchDrawState,
                }
            })
            .then(res => {
                if (res.code === 2000) {
                    // this.total = res.data.total;
                    const { custCouponList, couponConfAll } = res.data;
                    this.billlist = custCouponList.map(it => {
                        if (it.coupon_type === 3) it.coupon_discount_text = it.coupon_discount + "%";
                        if (it.coupon_amount === 0) it.coupon_amount = "";  // 0不显示
                        return it;
                    });
                    this.couponOpts = couponConfAll.map(it => {
                        if (it.name.length > 21) it.shortName = it.name.slice(0, 19) + "...";
                        return it;
                    });
                    this.loading = false;

                } else if (res.code !== 1003) {
                    this.$message({
                        message: res.msg || "获取客户卡券失败，请稍后重试",
                        type: "warning"
                    })
                }
            })
        },

        handleStatusClick (item) {
            this.logingItem = item;
        },
        // 点击发放券按钮
        handleIssueBtn () {
            this.issueData = createNewIssueData();
            this.showIssueUi = true;
        },
        handleDialogClose () {
            this.showIssueUi = false
        },
        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传三张图片",
            type: "error",
          });
        },
        beforeFileUpload(file) {
          const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
          const isLt2M = file.size / 1024 / 1024 < 5;
    
          if (!isImage) {
            this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 5MB!');
          }
          if (isImage && isLt2M) {
            this.issueData.files.push(file)
            this.uploading = true;
            return true;
          }
          return false;
        },
        handleProgress(e, file) {
            const index = this.issueData.files.indexOf(file);
            this.$set(this.issueData.files, index, file)
        },
        // 上传文件成功
        handleUploadSuccess (res, file) {
            this.uploading = false;
            const index = this.issueData.files.indexOf(file);
            if (res.code === 2000) {
                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.issueData.files, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
            } else {
                this.issueData.files.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },
        // 上传失败
        handleUploadError(err, file) {
            this.uploading = false;
            const index = this.issueData.files.indexOf(file);
            this.issueData.files.splice(index, 1);
            this.$message({
                message: "网络错误，请稍后再试",
                type: "error"
            })
        },
        // 删除文件
        handleIconDelete (index) {
            this.issueData.files.splice(index, 1);
        },
        // 确认发放
        handSubmitButton () {
            let loadingRef;
            this.$refs.issue.validate()
            .then(() => {

                let _index = this.couponOpts.findIndex((item)=>{
                    return item.id == this.issueData.id
                })
                if(this.couponOpts[_index].vipNum<1 && Number(this.isVip)==1){
                    this.$message({
                        message: '发劵失败，此劵类型不是会员权益劵',
                        type: "warning"
                    })
                    return
                }

                // console.log("提交发放", this.issueData)
                loadingRef = Loading.service({
                    target: ".el-dialog"
                })
                const { id, files, remark } = this.issueData;
                return this.$axios({
                    url: "api/custCoupon/giftCustCoupon",
                    method: "post",
                    data: {
                        remark,
                        couponConfId: id,
                        path: files.map(it => it.path).join(),
                        custId: this.profile.id,
                        isVip:Number(this.isVip) ,
                    }
                })
            })
            .then(res => {
                if (res.code === 2000) {
                    const {vipNo} = res.data;
                    if (!this.profile.vipNo)
                        this.profile.vipNo = vipNo;
                    this.fetchData()
                    .then(() => {
                        this.$message({
                            message: "操作成功",
                            type: "success"
                        });
                        if (loadingRef) loadingRef.close();
                    })
                    this.showIssueUi = false;
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.log("提交失败", reason)
                if (reason === false) {
                    // 数据没通过验证
                } else if (typeof reason === "string") {
                    this.$message({
                        message: reason,
                        type: "warning"
                    })

                } else if (reason?.msg) {
                    this.$message({
                        message: reason.msg,
                        type: "warning"
                    })

                } else {
                    // this.$message({
                    //     message: "网络出错",
                    //     type: "warning"
                    // })
                }
                if (loadingRef) loadingRef.close();
            })
        },

        // 标记为已使用
        // handleBulkVerify () {
        //     if (this.checkedlist.length > 1) return this.$message({
        //         message: "只能选择一条!",
        //         type: "error"
        //     })

        //     // 可使用的才能核销
        //     const someDisabled = this.checkedlist.some(it => it.status !== 1)
        //     if (someDisabled) return this.$message({
        //         message: "只能标记可使用的券!",
        //         type: "error"
        //     })

        //     let loadingRef;
        //     this.$confirm("要将选中的券标记为已使用吗")
        //     .then(() => {
        //         loadingRef = Loading.service();
        //         return this.$axios({
        //             url: "api/custCoupon/updateCustCoupon",
        //             method: "post",
        //             data: {
        //                 custCouponIds: this.checkedlist.map(it => it.id).join()
        //             }
        //         })
        //     })
        //     .then(res => {
        //         if (res.code === 2000) {
        //             this.fetchData()
        //             .then(() => {
        //                 this.$message({
        //                     message: "操作成功",
        //                     type: "success"
        //                 });
        //                 if (loadingRef) loadingRef.close();
        //             })
        //         } else if (res.code !== 1003) return Promise.reject(res)
        //     })
        //     .catch(reason => {
        //         if (reason === "cancel") {
        //             // 取消了操作
        //         } else {
        //             this.$message({
        //                 message: reason?.msg || "操作失败",
        //                 type: "error"
        //             })
        //         }
        //         if (loadingRef) loadingRef.close();
        //     })
        // },

        // 标记为已使用
        handleVerifyBtn () {
            if (this.checkedlist.length > 1) return this.$message({
                message: "只能选择一个优惠券!",
                type: "error"
            })
            console.log(this.checkedlist[0].status);

            // 可使用的才能核销
            const someDisabled = this.checkedlist.some(it => (it.status !== 1 &&  it.status !== 5))
            if (someDisabled) return this.$message({
                message: "只能标记待解锁或可使用的券!",
                type: "error"
            })

            this.verifingItem = this.checkedlist[0];

            // let loadingRef;
            // this.$confirm("要将选中的券标记为已使用吗")
            // .then(() => {
            //     loadingRef = Loading.service();
            //     return this.$axios({
            //         url: "api/custCoupon/updateCustCoupon",
            //         method: "post",
            //         data: {
            //             custCouponIds: this.checkedlist.map(it => it.id).join()
            //         }
            //     })
            // })
            // .then(res => {
            //     if (res.code === 2000) {
            //         this.fetchData()
            //         .then(() => {
            //             this.$message({
            //                 message: "操作成功",
            //                 type: "success"
            //             });
            //             if (loadingRef) loadingRef.close();
            //         })
            //     } else if (res.code !== 1003) return Promise.reject(res)
            // })
            // .catch(reason => {
            //     if (reason === "cancel") {
            //         // 取消了操作
            //     } else {
            //         this.$message({
            //             message: reason?.msg || "操作失败",
            //             type: "error"
            //         })
            //     }
            //     if (loadingRef) loadingRef.close();
            // })
        },

        // 批量删除
        handleBulkDelete () {
            // 已使用的不能删除
            const someDisabled = this.checkedlist.some(it => it.status === 2)
            if (someDisabled) return this.$message({
                message: "已使用的券不能删除!",
                type: "error"
            })

            let loadingRef;
            this.$confirm("要将选中的券删除吗").then(() => {
                loadingRef = Loading.service();
                return this.$axios({
                    url: "api/custCoupon/deleteCustCoupon",
                    method: "post",
                    data: {
                        custCouponIds: this.checkedlist.map(it => it.id).join()
                    }
                })
            })
            .then(res => {
                if (res.code === 2000) {
                    this.fetchData()
                    .then(() => {
                        this.$message({
                            message: "删除成功",
                            type: "success"
                        });
                        if (loadingRef) loadingRef.close();
                    })
                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                if (reason === "cancel") {
                    // 取消了操作
                } else {
                    this.$message({
                        message: reason?.msg || "删除失败",
                        type: "error"
                    })
                }
                if (loadingRef) loadingRef.close();
            })
        },

        // // 分页组件发生 每页数据条数 改变事件
        // handlePageSizeChange(e) {
        //     console.log("每页数据条数改变", e);
        //     this.SP.pageSize = e;
        //     this.SP.pageNum = 1;
        //     this.fetchData();
        // },

        // // 分页组件发生 当前页 改变事件
        // handlePageNoChange(e) {
        //     console.log("当前页改变", e);
        //     this.SP.pageNum = e;
        //     this.fetchData();
        // },
    },

    created () {
        this.auth.verify = this.$hasAuthFor("api/custCoupon/updateCustCoupon");
		this.auth.delete = this.$hasAuthFor("api/custCoupon/deleteCustCoupon");
		this.auth.issue  = this.$hasAuthFor("api/custCoupon/giftCustCoupon");
		this.auth.saveCouponGoods  = this.$hasAuthFor("api/custCoupon/saveCouponGoods");

    }
}
</script>

<style lang='scss' scoped>
.w80{
    width: 80px;
}
.flex{
    display: flex;
}
.a-center{
    align-items: center;
}
.isviprow{
    display: flex;
    align-items: center;
}
.isviprow /deep/ .el-form-item__content{
    margin-left: 0 !important;

}
.myDialog /deep/ .el-dialog__body{
    max-height: initial !important;
}
    
</style>